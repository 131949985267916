import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { MEMBERSHIP_TYPE_MAP } from '../constants';
import { FIRST_LAUNCH_WEEK } from '../constants/destiny';
import { Activity, ActivityValues } from '../types/activity';
import { AggregateActivity } from '../types/aggregate-activity';
import { MembershipInfo } from '../types/membership-info';
import { BasePlayerCountActivityDetails } from '../types/raid-report';
import { BaseActivityDetails, BasicValue } from '../types/shared';
import {
  getTimeDisplayValue,
  ONE_SEC_IN_MS,
  ONE_WEEK_IN_SEC,
} from './ui-helper';

export const getPathForMembershipInfo = (membershipInfo: MembershipInfo) => {
  const membershipTypeInfo = MEMBERSHIP_TYPE_MAP[membershipInfo.membershipType];
  if (!membershipTypeInfo) {
    return '/';
  }
  return decodeURI(
    `/${membershipTypeInfo.displayName}/${membershipInfo.membershipId}`
  );
};

export const getLootableRaids = (activityHashes, raidsByResetDate) => {
  return Object.keys(raidsByResetDate)
    .map((date) => {
      const weeklyRuns = raidsByResetDate[date].filter((activity) =>
        activityHashes.some(
          (hash) => hash === activity.activityDetails.referenceId
        )
      );
      return uniqBy(weeklyRuns, 'characterId').length;
    })
    .reduce((a, b) => a + b, 0);
};

export const getResetDateForDate = (date: moment.Moment) => {
  const diff = date.diff(FIRST_LAUNCH_WEEK, 's') % ONE_WEEK_IN_SEC;
  return date.subtract(diff, 's').utc().format();
};

export const getFastestCompletionSecondsForActivity = (
  aggregateActivity: AggregateActivity
): BasicValue => {
  const fastestCompletionMsForActivityValue =
    aggregateActivity.values.fastestCompletionMsForActivity.basic.value;
  const value = Math.trunc(fastestCompletionMsForActivityValue / ONE_SEC_IN_MS);
  return {
    basic: {
      value,
      displayValue: getTimeDisplayValue(value),
    },
  };
};

export const getActivityEndDate = (activity: Activity) => {
  const activityStartDate = moment(activity.period);
  return activityStartDate.add(
    activity.values.activityDurationSeconds.basic.value,
    's'
  );
};

export const getResetDateForActivity = (activity: Activity) => {
  const activityEndDate = getActivityEndDate(activity);
  return getResetDateForDate(activityEndDate);
};

export const getCurrentResetDate = () => getResetDateForDate(moment());

export const formatNumberAsString = (value: number) =>
  value.toLocaleString(navigator.language);

export const combineBasicValue = (
  a: BasicValue,
  b: BasicValue,
  formatDisplayValue: (value: number) => string = formatNumberAsString
): BasicValue => {
  const value = a.basic.value + b.basic.value;
  return {
    basic: {
      value,
      displayValue: formatDisplayValue(value),
    },
  };
};

export const minPositiveBasicValue = (
  a: BasicValue,
  b: BasicValue
): BasicValue => {
  return b.basic.value <= 0 ||
    (a.basic.value > 0 && a.basic.value < b.basic.value)
    ? a
    : b;
};

export const combineAggregateActivities = (
  a: AggregateActivity,
  b: AggregateActivity
) => {
  return {
    activityHash: NaN,
    values: {
      activityAssists: combineBasicValue(
        a.values.activityAssists,
        b.values.activityAssists
      ),
      activityCompletions: combineBasicValue(
        a.values.activityCompletions,
        b.values.activityCompletions
      ),
      activityDeaths: combineBasicValue(
        a.values.activityDeaths,
        b.values.activityDeaths
      ),
      activityKills: combineBasicValue(
        a.values.activityKills,
        b.values.activityKills
      ),
      activitySecondsPlayed: combineBasicValue(
        a.values.activitySecondsPlayed,
        b.values.activitySecondsPlayed,
        getTimeDisplayValue
      ),
      fastestCompletionMsForActivity: minPositiveBasicValue(
        a.values.fastestCompletionMsForActivity,
        b.values.fastestCompletionMsForActivity
      ),
    },
  };
};

export const getAccountCount = (d?: BasePlayerCountActivityDetails) =>
  d && d.accountCount;
export const getInstanceId = <T extends BaseActivityDetails>(d?: T) =>
  d && Number(d.instanceId);
export const getFresh = (d?: BasePlayerCountActivityDetails) =>
  d && d.fresh ? 0 : 1;
export const getActivityCompleted = (values: ActivityValues) => {
  return (
    values.completed.basic.value === 1 &&
    values.completionReason.basic.value === 0 &&
    !(values.assists.basic.value === 0 && values.kills.basic.value === 0)
  );
};
