import {
  forkJoin as observableForkJoin,
  merge as observableMerge,
  of as observableOf,
} from 'rxjs';
import { mergeMap, reduce } from 'rxjs/operators';
import { AccountStatsResponse } from '../../types/account-stats';
import { AccountSummary } from '../../types/account-summary';
import { Activity, ActivityResponse } from '../../types/activity';
import { AggregateActivity } from '../../types/aggregate-activity';
import { LinkedProfiles } from '../../types/linked-profiles';
import {
  CompleteMembershipRequest,
  MembershipInfo,
} from '../../types/membership-info';
import { Milestones } from '../../types/milestones';
import { PGCR } from '../../types/pgcr';
import { BUNGIE_PGCR_URL } from './constants';
import { createDestinyRequest } from './util';

export const getAccountSummary = (
  player: MembershipInfo,
  componentsArray = ['Profiles', 'Characters', 'Records']
) => {
  const path = [player.membershipType, 'Profile', player.membershipId];
  const components = componentsArray.join(',');
  const qs = {
    components,
  };
  return createDestinyRequest<AccountSummary>(path, qs);
};

export const getMembershipInfo = (displayName: string) => {
  const encodedDisplayName = encodeURIComponent(displayName);
  const path = ['SearchDestinyPlayer', -1, encodedDisplayName];
  return createDestinyRequest<MembershipInfo[]>(path);
};

export const getCharacterActivities = (
  player: MembershipInfo,
  characterId: string,
  mode = 'None',
  page = 0
) => {
  const maxCount = 250;
  const path = [
    player.membershipType,
    'Account',
    player.membershipId,
    'Character',
    characterId,
    'Stats',
    'Activities',
  ];
  const qs = {
    page,
    mode,
    count: maxCount,
  };
  return createDestinyRequest<ActivityResponse<Activity>>(path, qs).pipe(
    mergeMap((data) => {
      const activities = data.activities || [];
      activities.forEach((a) => {
        // eslint-disable-next-line no-param-reassign
        a.characterId = characterId;
      });
      let result$ = observableOf(activities);
      if (activities.length === maxCount) {
        result$ = observableMerge(
          result$,
          getCharacterActivities(player, characterId, mode, page + 1)
        );
      }
      return result$;
    }),
    reduce((a: Activity[], b: Activity[]) => a.concat(b), [])
  );
};

export const getPlayerActivities = (
  player: CompleteMembershipRequest,
  mode: string
) => {
  return observableForkJoin(
    player.characterIds.map((id) => {
      return getCharacterActivities(player.membershipInfo, id, mode);
    })
  );
};

export const getCharacterAggregateActivityStats = (
  player: MembershipInfo,
  characterId: string
) => {
  const path = [
    player.membershipType,
    'Account',
    player.membershipId,
    'Character',
    characterId,
    'Stats',
    'AggregateActivityStats',
  ];
  return createDestinyRequest<ActivityResponse<AggregateActivity>>(path);
};

export const getPlayerAggregateActivityStats = (
  player: CompleteMembershipRequest
) => {
  return observableForkJoin(
    player.characterIds.map((id) => {
      return getCharacterAggregateActivityStats(player.membershipInfo, id);
    })
  );
};

export const getAccountStats = (player: MembershipInfo) => {
  const path = [player.membershipType, 'Account', player.membershipId, 'Stats'];
  return createDestinyRequest<AccountStatsResponse>(path);
};

export const getPGCR = (activityId: string) => {
  const path = ['Stats', 'PostGameCarnageReport', activityId];
  return createDestinyRequest<PGCR>(path, undefined, BUNGIE_PGCR_URL);
};

export const getMilestones = () => {
  const path = ['Milestones'];
  return createDestinyRequest<Milestones>(path);
};

export const getLinkedProfiles = (bungieId: string) => {
  const path = ['254', 'Profile', bungieId, 'LinkedProfiles'];
  const qs = { getAllMemberships: true };
  return createDestinyRequest<LinkedProfiles>(path, qs);
};
