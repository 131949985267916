import moment from 'moment';
import { createSelector } from 'reselect';
import { RootState } from '..';
import { AuthResponse } from '../../types/auth';
import { getFeaturesFromStripe } from '../../utils/user';

const getUserState = (state: RootState) => state.user;

export const getUserAuthState = (
  state: RootState
): AuthResponse | undefined => {
  return getUserState(state).response?.bungieAuth;
};

export const getUserLoading = (state: RootState) => {
  return getUserState(state).loading;
};

export const getUserStripeSubscriptions = (state: RootState) => {
  return getUserState(state).response?.stripeSubscriptions;
};

export const getUserActiveStripeSubscriptions = (state: RootState) => {
  return getUserState(state).response?.stripeSubscriptions?.filter((sub) => {
    return (
      sub.status === 'active' &&
      moment(sub.currentPeriodEnd)
        .add(sub.cancelAtPeriodEnd ? 0 : 1, 'd')
        .isAfter()
    );
  });
};

export const getUserFeatures = createSelector(
  getUserActiveStripeSubscriptions,
  getFeaturesFromStripe
);
