import { useMutation, UseMutationOptions } from 'react-query';
import { useSelector } from 'react-redux';
import config from '../../environment';
import { getStripeCheckoutSession } from '../../services/user/service';
import { getUserAuthState } from '../../store/user/selectors';
import { Price, SubscriptionTier } from '../../types/subscription';
import { waitForRedirect } from '../../utils/promise';

type Params = {
  tier: SubscriptionTier;
  interval: Price['interval'];
};

type Options = UseMutationOptions<void, unknown, Params, unknown> | undefined;

export const useStripeCheckout = (options?: Options) => {
  const authState = useSelector(getUserAuthState);
  return useMutation(async ({ tier, interval }: Params) => {
    if (!authState) throw new Error('No user is logged in');
    const response = await getStripeCheckoutSession({
      client_id: config.BUNGIE_CLIENT_ID,
      refresh_token: authState.refresh_token,
      grant_type: 'refresh_token',
      tier,
      interval,
    });
    await waitForRedirect(response.url);
  }, options);
};
