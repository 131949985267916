import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/includes';
import 'core-js/features/math/log10';
import 'core-js/features/math/trunc';
import 'core-js/features/string/trim-end';
import 'react-app-polyfill/ie11';
import { polyfill } from 'smoothscroll-polyfill';

if (!window.crypto) {
  window.crypto = window.msCrypto;
}

polyfill();
