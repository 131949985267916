import { BungieAuthRequest } from '../../types/auth';
import { Price, SubscriptionTier } from '../../types/subscription';
import { UserResponse } from '../../types/user';
import { createUserPostRequest } from './util';

export const postBungieAuth = (body: BungieAuthRequest) => {
  const path = ['auth', 'bungie'];
  return createUserPostRequest<UserResponse>(path, body);
};

export const getStripeCheckoutSession = (
  body: BungieAuthRequest & {
    tier: SubscriptionTier;
    interval: Price['interval'];
  }
) => {
  const path = ['checkout', 'stripe'];
  return createUserPostRequest<{ url: string }>(path, body).toPromise();
};

export const getStripePortalSession = (body: BungieAuthRequest) => {
  const path = ['portal', 'stripe'];
  return createUserPostRequest<{ url: string }>(path, body).toPromise();
};
