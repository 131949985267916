import { MembershipInfo } from './membership-info';
import { PGCRPlayer } from './pgcr';
import { RAID } from './raid-report';
import { SubscriptionTier } from './subscription';

export interface LeaderboardMembershipInfo extends MembershipInfo {
  activeSubscriptions?: SubscriptionTier[];
}

export enum LEADERBOARD_TYPE {
  WORLDS_FIRST = 'worldsfirst',
  CLEAR = 'clears',
  SHERPA = 'sherpa',
  SPEED = 'speed',
}

export enum Version {
  All = 'all',
  Normal = 'normal',
  Prestige = 'prestige',
  Master = 'master',
  PC = 'pc',
  Legacy = 'legacy',
  Challenge = 'challenge',
}

export interface PGCRLeaderboardPlayer {
  player: PGCRPlayer;
  completed: number;
  characterId: string;
}

export interface PGCRLeaderboardEntry {
  rank: number;
  instanceId: string;
  players: PGCRLeaderboardPlayer[];
  period: string;
  durationSeconds: number;
}

export interface LeaderboardResponse {
  metadata: LeaderboardMetadata;
  entries: Array<LeaderboardEntry | PlayerLeaderboardEntry>;
}

export interface LeaderboardEntry {
  rank: number;
  value: number;
}

export interface LeaderboardActivity extends LeaderboardEntry {
  activityDetails: {
    instanceId: string;
    membershipType: number;
  };
}

export interface PlayerLeaderboardEntry extends LeaderboardEntry {
  destinyUserInfo: LeaderboardMembershipInfo;
}

export interface ActivityPlayerLeaderboardEntry
  extends PlayerLeaderboardEntry,
    LeaderboardActivity {}

export interface InstanceLeaderboardEntry extends LeaderboardActivity {
  destinyUserInfos: LeaderboardMembershipInfo[];
}

export type LeaderboardQueryStrings = {
  page?: number;
  pageSize?: number;
  membershipId?: string;
  membershipType?: number[];
};

export interface LeaderboardRequestMetadata extends LeaderboardQueryStrings {
  type: LEADERBOARD_TYPE;
  raid: RAID;
  version?: string;
}

export interface LeaderboardMetadata extends LeaderboardRequestMetadata {
  totalResults: number;
  page: number;
  pageSize: number;
}
