import {
  List,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { ReactNode } from 'react';
import { IoMdMedal } from 'react-icons/io';
import { MdCheck } from 'react-icons/md';
import { Subscription } from '../../../../types/subscription';
import Sparkles from '../../../LeaderboardContainer/components/Sparkles';

const ListItem = ({ children }: { children: ReactNode }) => {
  return (
    <MuiListItem>
      <ListItemIcon>
        <MdCheck />
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{ variant: 'body1' }}>
        {children}
      </ListItemText>
    </MuiListItem>
  );
};

const Features = ({
  subscription: {
    features,
    displayProperties: { title, color },
  },
}: {
  subscription: Subscription;
}) => {
  return (
    <List disablePadding dense>
      {features.removesAds && <ListItem>No ads</ListItem>}
      {features.addsProfileBadge && (
        <ListItem>
          <IoMdMedal color={color} /> Badge on your profile page
        </ListItem>
      )}
      {features.addsLeaderboardFlair && (
        <ListItem>
          <Sparkles />
          Highlight leaderboard entries
        </ListItem>
      )}
      <ListItem>
        Perks apply to{' '}
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href="https://raid.report" target="_blank" rel="noopener">
          raid.report
        </a>{' '}
        and {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href="https://dungeon.report" target="_blank" rel="noopener">
          dungeon.report
        </a>
      </ListItem>
      <ListItem>Cancel at any time</ListItem>
    </List>
  );
};

export default Features;
