import localforage from 'localforage';
import { useSelector } from 'react-redux';
import { createMigrate, PersistConfig, PersistedState } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { RootState } from '.';
import { transforms } from './reducers';

type MigrateState = (PersistedState & Partial<RootState>) | undefined;

const migrations: {
  [newVersion: number]: (state: MigrateState) => MigrateState;
} = {
  2: (state: MigrateState) => {
    if (state?._persist.version !== 1 || state.settings?.theme !== 'light') {
      return state;
    }
    return {
      ...state,
      settings: {
        ...state.settings,
        theme: 'device',
      },
    };
  },
};

localforage.config({
  name: 'dungeonreport',
  storeName: 'reduxstate',
});

export const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  storage: localforage,
  whitelist: ['settings', 'notification', 'user'],
  version: 2,
  stateReconciler: autoMergeLevel2,
  transforms,
  migrate: createMigrate(migrations as any, { debug: false }),
};

export const useRehydrated = () =>
  useSelector((state: RootState) => state._persist?.rehydrated);
