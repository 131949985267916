import { MembershipInfo } from './membership-info';

// TODO rename to reflect new end point
export interface AccountSummary {
  characters: ComponentResponse<CharacterComponentDictionary>;
  profile: ComponentResponse<ProfileComponent>;
  // TODO all data should be optional because of privacy
  profileRecords: ComponentResponse<ProfileRecordsComponent | undefined>;
  characterRecords: ComponentResponse<CharacterRecordsComponent | undefined>;
}

export enum ComponentPrivacy {
  None,
  Public,
  Private,
}

export interface ComponentResponse<T> {
  data: T;
  privacy: ComponentPrivacy;
}

export interface ProfileComponent {
  userInfo: MembershipInfo;
  characterIds: number[];
  dateLastPlayed?: string;
}

export interface CharacterComponentDictionary {
  [characterId: string]: CharacterComponent;
}

export interface CharacterComponent {
  membershipId: string;
  membershipType: number;
  characterId: string;
  light: number;
  classHash: number;
  emblemPath: string;
  emblemBackgroundPath: string;
  dateLastPlayed: string;
}

export interface CharacterRecordsComponent {
  [characterId: string]: RecordsComponent;
}

export interface ProfileRecordsComponent extends RecordsComponent {
  score: number;
}

export interface RecordsComponent {
  records: {
    [recordHash: string]: RecordComponent;
  };
}

export enum DestinyRecordState {
  None = 0,
  RecordRedeemed = 1,
  RewardUnavailable = 2,
  ObjectiveNotCompleted = 4,
  Obscured = 8,
  Invisible = 16,
  EntitlementUnowned = 32,
  CanEquipTitle = 64,
}

export interface RecordComponent {
  state: DestinyRecordState;
  objectives?: ObjectiveProgress[];
}

export interface ObjectiveProgress {
  objectiveHash: number;
  destinationHash?: number;
  activityHash?: number;
  progress?: number;
  completionValue: number;
  complete: boolean;
  visible: boolean;
}
