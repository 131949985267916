import { ConnectedRouter } from 'connected-react-router';
import { HelmetProvider } from 'react-helmet-async';
import { IconContext } from 'react-icons';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import AppContainer from './AppContainer';
import { ServiceWorkerProvider } from './serviceWorkerContext';
import { history, store } from './store';
import ThemeContainer from './ThemeContainer';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: 'tracked',
    },
  },
});

const iconContext = { style: { verticalAlign: 'middle' } };

const App = () => {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <ThemeContainer>
          <ConnectedRouter history={history}>
            <IconContext.Provider value={iconContext}>
              <ServiceWorkerProvider>
                <QueryClientProvider client={queryClient}>
                  <AppContainer />
                  <ReactQueryDevtools />
                </QueryClientProvider>
              </ServiceWorkerProvider>
            </IconContext.Provider>
          </ConnectedRouter>
        </ThemeContainer>
      </HelmetProvider>
    </Provider>
  );
};

export default App;
