import actionCreatorFactory from 'typescript-fsa';
import { MembershipInfo } from '../../types/membership-info';

enum Action {
  REQUEST_MEMBERSHIP_INFO,
  RECEIVE_MEMBERSHIP_INFO,
}

const actionCreator = actionCreatorFactory('LEADERBOARD');
export const requestMembershipInfo = actionCreator<Partial<MembershipInfo>>(
  Action[Action.REQUEST_MEMBERSHIP_INFO]
);
export const receiveMembershipInfo = actionCreator<MembershipInfo>(
  Action[Action.RECEIVE_MEMBERSHIP_INFO]
);

export const ActionCreators = {
  requestMembershipInfo,
  receiveMembershipInfo,
};
