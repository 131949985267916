import { Theme, withStyles, withTheme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme';
import { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import AppLayout from './AppLayout';
import DelayedLoadingBar from './components/DelayedLoadingBar';
import ErrorBoundary from './components/ErrorBoundary';
import MetaTags from './components/MetaTags';
import { SITE_META_NAME } from './constants';
import { INDIVIDUAL_RAIDS } from './constants/destiny';
import ModalContainer from './containers/ModalContainer';
import NotificationContainer from './containers/NotificationContainer';
import HeaderContainer from './layouts/HeaderContainer';
import { useRehydrated } from './store/db';
import { refetchUserFromState } from './store/user/reducer';
import { getUserAuthState } from './store/user/selectors';
import { Version } from './types/leaderboard';
import { RAID } from './types/raid-report';

const fallback = (
  <main className="drr-container">
    <DelayedLoadingBar />
  </main>
);

const Changelog = lazy(
  () => import(/* webpackChunkName: 'Changelog' */ './components/Changelog')
);
const FAQ = lazy(
  () => import(/* webpackChunkName: 'FAQ' */ './components/FAQ')
);
const Terms = lazy(
  () => import(/* webpackChunkName: 'Terms' */ './components/Terms')
);
const PrivacyPolicy = lazy(
  () =>
    import(/* webpackChunkName: 'PrivacyPolicy' */ './components/PrivacyPolicy')
);
const Ranks = lazy(
  () => import(/* webpackChunkName: 'Ranks' */ './components/Ranks')
);
const HomeContainer = lazy(
  () =>
    import(/* webpackChunkName: 'HomeContainer' */ './containers/HomeContainer')
);
const PGCRContainer = lazy(
  () =>
    import(/* webpackChunkName: 'PGCRContainer' */ './containers/PGCRContainer')
);
const RaidReportContainer = lazy(
  () =>
    import(
      /* webpackChunkName: 'RaidReportContainer' */ './containers/RaidReportContainer'
    )
);
const LeaderboardContainer = lazy(
  () =>
    import(
      /* webpackChunkName: 'LeaderboardContainer' */ './containers/LeaderboardContainer'
    )
);
const SettingsContainer = lazy(
  () =>
    import(
      /* webpackChunkName: 'SettingsContainer' */ './containers/SettingsContainer'
    )
);
const AccountContainer = lazy(
  () =>
    import(
      /* webpackChunkName: 'AccountContainer' */ './containers/AccountContainer'
    )
);

const shortDescription =
  'Destiny dungeon stats, leaderboards, and weekly progress. Look up dungeon clears, speedruns, and sherpas for all Destiny 2 dungeons';
const headTranslations = {
  shortDescription,
  description: `${shortDescription} - ${INDIVIDUAL_RAIDS.map(
    (r) => r.displayValue
  ).join(', ')}.`,
};

const AppContainer = ({ theme }: ThemedComponentProps) => {
  let className = 'page-flexbox-wrapper';
  if (theme && theme.palette.type === 'dark') {
    className += ' drr-dark';
  }
  const location = useLocation();

  const store = useStore();
  const dispatch = useDispatch();
  const rehydrated = useRehydrated();
  useEffect(() => {
    const authState = getUserAuthState(store.getState());
    if (rehydrated && authState) {
      dispatch(refetchUserFromState.started(authState));
    }
  }, [dispatch, rehydrated, store]);

  return (
    <div className={className}>
      <MetaTags
        title={SITE_META_NAME}
        description={headTranslations.description}
        shortDescription={headTranslations.shortDescription}
        addSuffix={false}
      />
      <HeaderContainer />
      <ModalContainer />
      <NotificationContainer />
      <ErrorBoundary key={location.pathname}>
        <Suspense fallback={fallback}>
          <AppLayout showFooter={rehydrated}>
            <Switch>
              <Redirect
                exact
                from={`/leaderboard/:leaderboardType/${RAID.PRESAGE}/${Version.Prestige}`}
                to={{
                  ...location,
                  pathname: `/leaderboard/:leaderboardType/${RAID.PRESAGE}/${Version.Master}`,
                }}
              />
              <Route exact path="/" component={HomeContainer} />
              <Route exact path="/pgcr/:activityId" component={PGCRContainer} />
              <Route
                exact
                path="/:membershipType/:displayName"
                component={RaidReportContainer}
              />
              <Route exact path="/privacy" component={PrivacyPolicy} />
              <Route exact path="/faq" component={FAQ} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/changelog" component={Changelog} />
              <Route exact path="/ranks" component={Ranks} />
              <Route exact path="/settings" component={SettingsContainer} />
              <Route exact path="/account" component={AccountContainer} />
              <Route
                exact
                path="/leaderboard/:leaderboardType/:raidName"
                component={LeaderboardContainer}
              />
              <Route
                exact
                path="/leaderboard/:leaderboardType/:raidName/:raidVersion"
                component={LeaderboardContainer}
              />
              <Redirect to="/" />
            </Switch>
          </AppLayout>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};
const styles = (theme: Theme): StyleRules => {
  return {
    '@global': {
      body: {
        backgroundColor: theme.palette.background.default,
      },
      ':root': {
        colorScheme: theme.palette.type,
      },
    },
  };
};
export default withStyles(styles)(withTheme(AppContainer));
