import { EnvironmentConfig } from './types/environment';

const local: EnvironmentConfig = {
  RAID_REPORT_ENDPOINT: 'http://localhost:8080',
  SEARCH_ENDPOINT: 'http://localhost:8082',
  NOTIFICATION_BRANCH: 'dungeon-dev',
  BUNGIE_CLIENT_ID: 34123,
  USER_API: 'http://localhost:8081',
};

const development: EnvironmentConfig = {
  RAID_REPORT_ENDPOINT: 'https://api.raidreport.dev/dev/dungeon',
  SEARCH_ENDPOINT: 'https://api.raidreport.dev/dev',
  NOTIFICATION_BRANCH: 'dungeon-dev',
  BUNGIE_CLIENT_ID: 33973,
  USER_API: 'https://p6vkf8wgcb.execute-api.us-west-2.amazonaws.com',
  AUTH_STATE: `${window.location.origin}/account`,
};

const production: EnvironmentConfig = {
  RAID_REPORT_ENDPOINT: 'https://api.raidreport.dev/dungeon',
  SEARCH_ENDPOINT: 'https://api.raidreport.dev',
  NOTIFICATION_BRANCH: 'dungeon-v2',
  BUNGIE_CLIENT_ID: 35333,
  USER_API: 'https://2vr76ha3fi.execute-api.us-west-2.amazonaws.com',
};

// eslint-disable-next-line import/no-mutable-exports
let config: EnvironmentConfig;

switch (process.env.REACT_APP_ENV) {
  case 'production':
    config = production;
    break;
  case 'development':
    config = development;
    break;
  case 'local':
    config = local;
    break;
  default:
    throw new Error('Invalid REACT_APP_ENV value set');
}

export default config;
