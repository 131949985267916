import {
  Observable,
  of as observableOf,
  throwError as observableThrowError,
} from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BungieResponse } from '../../types/shared';
import { createRequest } from '../util';
import {
  BUNGIE_HEADERS,
  BUNGIE_URL,
  DESTINY_PATH,
  USER_PATH,
} from './constants';

const createBungieRequest = <T>(
  path: Array<string | number>,
  qs: any,
  baseUrl: string = BUNGIE_URL
): Observable<BungieResponse<T>> => {
  path.unshift(baseUrl);
  return createRequest<BungieResponse<T>>(path, qs, BUNGIE_HEADERS, true).pipe(
    mergeMap((body) =>
      body.ErrorCode === 1 ? observableOf(body) : observableThrowError(body)
    ),
    catchError((error) => observableThrowError(error.response || error))
  );
};

export const createAndResolveBungieRequest = <T>(
  path: Array<string | number>,
  qs: any = undefined,
  baseUrl?: string
): Observable<T> => {
  return createBungieRequest<T>(path, qs, baseUrl).pipe(
    map((body) => body.Response)
  );
};

export const createUserRequest = <T>(
  path: Array<string | number>,
  qs: any = undefined
): Observable<T> => {
  path.unshift(USER_PATH);
  return createAndResolveBungieRequest<T>(path, qs);
};

export const createDestinyRequest = <T>(
  path: Array<string | number>,
  qs: any = undefined,
  baseUrl?: string
): Observable<T> => {
  path.unshift(DESTINY_PATH);
  return createAndResolveBungieRequest<T>(path, qs, baseUrl);
};
