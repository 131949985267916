import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import actionCreatorFactory from 'typescript-fsa';
import { ProfileListItem } from '../../../components/ProfileListItem';
import {
  hideModal,
  ModalShowState,
  SelectorProps,
} from '../../../store/modal/reducer';
import { MembershipInfo } from '../../../types/membership-info';

const useItemStyles = makeStyles(() => ({
  text: {
    minWidth: 115,
  },
}));

const getDisplayName = ({
  displayName,
  bungieGlobalDisplayName,
  bungieGlobalDisplayNameCode,
}: MembershipInfo) => {
  if (bungieGlobalDisplayName && bungieGlobalDisplayNameCode) {
    const separator = bungieGlobalDisplayNameCode < 1000 ? '#0' : '#';
    return bungieGlobalDisplayName + separator + bungieGlobalDisplayNameCode;
  }
  return displayName;
};

const selectorModalTranslations = {
  selectorHeading: 'Select player',
};

type Props = ModalShowState & SelectorProps;

const SelectorModal = ({
  receiveMembershipInfoType,
  membershipInfos,
  showModal,
}: Props) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const itemClasses = useItemStyles();
  const receiveMembershipInfo = actionCreatorFactory()<MembershipInfo>(
    receiveMembershipInfoType
  );
  const createMembershipEntry = (membershipInfo: MembershipInfo) => {
    const onClick = (event) => {
      event.preventDefault();
      dispatch(hideModal());
      dispatch(receiveMembershipInfo(membershipInfo));
    };
    return (
      <ProfileListItem
        key={membershipInfo.membershipId}
        userInfo={membershipInfo}
        onClick={onClick}
        classes={itemClasses}
        primaryText={getDisplayName(membershipInfo)}
      />
    );
  };

  const membershipEntries = membershipInfos.map(createMembershipEntry);
  const onClose = () => dispatch(hideModal());
  return (
    <Dialog
      open={showModal}
      onClose={onClose}
      aria-labelledby="selector-dialog-title"
      className={palette.type === 'dark' ? 'drr-dark' : ''}
    >
      <DialogTitle id="selector-dialog-title">
        {selectorModalTranslations.selectorHeading}
      </DialogTitle>
      <DialogContent>
        <List dense component="div">
          {membershipEntries}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default SelectorModal;
