import {
  Avatar,
  Badge,
  BadgeOrigin,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Skeleton } from '@material-ui/lab';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { BUNGIE_URL, MEMBERSHIP_TYPE_MAP } from '../constants';
import { getAccountSummary } from '../services/bungie/destiny';
import { MembershipInfo } from '../types/membership-info';
import { getPathForMembershipInfo } from '../utils/app-helper';

const badgeOrigin: BadgeOrigin = { vertical: 'bottom', horizontal: 'right' };

export const ProfileListItem = ({
  userInfo,
  onClick,
  primaryText,
  classes,
}: {
  userInfo: MembershipInfo;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  primaryText: string;
  classes?: ClassNameMap<'text'>;
}) => {
  const { data, isLoading, isError, error } = useQuery(
    ['accountSummary', userInfo.membershipId],
    () => getAccountSummary(userInfo, ['Characters']).toPromise(),
    {
      retry: (failureCount, e: any) => {
        return failureCount < 2 && e?.ErrorCode !== 1601;
      },
    }
  );
  const characters = data?.characters.data || {};
  const character = orderBy(
    Object.keys(characters).map((key) => characters[key]),
    (c) => c.dateLastPlayed,
    ['desc']
  )[0];
  const { emblemPath, dateLastPlayed } = character || userInfo;

  const membershipTypeInfo = MEMBERSHIP_TYPE_MAP[userInfo.membershipType];
  const badgeContent = membershipTypeInfo && (
    <membershipTypeInfo.icon colored />
  );
  const link = getPathForMembershipInfo(userInfo);

  const getSecondaryText = () => {
    if (dateLastPlayed) return moment(dateLastPlayed).fromNow();
    if (data || isError) {
      return data || error.ErrorCode === 1601 ? 'Invalid' : 'Error';
    }
    return <Skeleton />;
  };

  const avatar = (
    <Avatar
      src={emblemPath && `${BUNGIE_URL}${emblemPath}`}
      imgProps={{ role: 'presentation' }}
    />
  );

  return (
    <ListItem button to={link} onClick={onClick} component={Link}>
      <ListItemAvatar>
        <Badge badgeContent={badgeContent} anchorOrigin={badgeOrigin}>
          {isLoading && !emblemPath ? (
            <Skeleton variant="circle">{avatar}</Skeleton>
          ) : (
            avatar
          )}
        </Badge>
      </ListItemAvatar>
      <ListItemText
        primary={primaryText}
        secondary={getSecondaryText()}
        className={classes?.text}
      />
    </ListItem>
  );
};
