import { createTransform } from 'redux-persist';
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { AuthResponse } from '../../types/auth';
import { UserResponse } from '../../types/user';

export interface UserState {
  response?: UserResponse;
  loading?: boolean;
  hasDismissedTooltip?: boolean;
}

const initialState: UserState = {};

export const userTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  ({ loading, ...inboundState }: Partial<UserState>) => {
    return inboundState;
  },
  // transform state being rehydrated
  (outboundState) => {
    return outboundState;
  },
  // define which reducers this transform gets called for.
  { whitelist: ['user'] }
);

enum Action {
  GET_USER_FROM_URL,
  GET_USER_FROM_STATE,
  CLEAR_USER,
  DISMISS_USER_TOOLTIP,
}

const actionCreator = actionCreatorFactory('User');

export const fetchUserFromCode = actionCreator.async<string, UserResponse>(
  Action[Action.GET_USER_FROM_URL]
);

export const refetchUserFromState = actionCreator.async<
  AuthResponse,
  UserResponse
>(Action[Action.GET_USER_FROM_STATE]);
export const clearUser = actionCreator(Action[Action.CLEAR_USER]);
export const dismissUserTooltip = actionCreator(
  Action[Action.DISMISS_USER_TOOLTIP]
);

export const ActionCreators = {
  fetchUserFromCode,
  refetchUserFromState,
};

export default reducerWithInitialState(initialState)
  .cases([fetchUserFromCode.started, refetchUserFromState.started], (state) => {
    return { ...state, loading: true };
  })
  .cases(
    [fetchUserFromCode.done, refetchUserFromState.done],
    (state, { result }) => {
      return { ...state, response: result, loading: false };
    }
  )
  .cases(
    [fetchUserFromCode.failed, refetchUserFromState.failed, clearUser],
    (state) => {
      return {
        ...initialState,
        hasDismissedTooltip: state.hasDismissedTooltip,
      };
    }
  )
  .case(dismissUserTooltip, (state) => {
    return { ...state, hasDismissedTooltip: true };
  });
