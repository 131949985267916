import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { MembershipInfo } from '../../types/membership-info';
import { Subscription } from '../../types/subscription';

export enum ModalType {
  NO_OP,
  SELECT_MEMBERSHIP_TYPE,
  ERROR_TYPE,
  UPSELL_TYPE,
}

export interface ModalShowState {
  showModal: boolean;
}

export interface ModalState extends ModalShowState {
  modalType?: ModalType;
  modalProps: any;
}

export interface ErrorProps {
  errorHeading: string;
  errorMessage: string;
  showReport?: boolean;
  privacyError?: boolean;
}

export interface SelectorProps {
  membershipInfos: MembershipInfo[];
  receiveMembershipInfoType: string;
}

export interface UpsellProps {
  subscription: Subscription;
}

const initialState: ModalState = {
  modalProps: {},
  showModal: false,
};

enum Action {
  SHOW_MODAL,
  HIDE_MODAL,
}
const actionCreator = actionCreatorFactory('MODAL');
export const showModal = actionCreator<
  Pick<ModalState, Exclude<keyof ModalState, keyof ModalShowState>>
>(Action[Action.SHOW_MODAL]);
export const hideModal = actionCreator(Action[Action.HIDE_MODAL]);

export const showSelectorModal = (modalProps: SelectorProps) => {
  return showModal({
    modalProps,
    modalType: ModalType.SELECT_MEMBERSHIP_TYPE,
  });
};

export const showErrorModal = (modalProps: ErrorProps) => {
  return showModal({
    modalProps,
    modalType: ModalType.ERROR_TYPE,
  });
};

export const showUpsellModal = (modalProps: UpsellProps) => {
  return showModal({
    modalProps,
    modalType: ModalType.UPSELL_TYPE,
  });
};

export default reducerWithInitialState(initialState)
  .case(showModal, (state, payload) => {
    return { ...payload, showModal: true };
  })
  .case(hideModal, (state) => {
    return { ...state, showModal: false };
  });
