import { LocationChangeAction, replace } from 'connected-react-router';
import {
  ActionsObservable,
  combineEpics,
  StateObservable,
} from 'redux-observable';
import {
  from as observableFrom,
  interval as observableInterval,
  of as observableOf,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  throttle,
} from 'rxjs/operators';
import { Action } from 'typescript-fsa';
import { RootState } from '..';
import config from '../../environment';
import { postBungieAuth } from '../../services/user/service';
import { ONE_MIN_IN_SEC, ONE_SEC_IN_MS } from '../../utils/ui-helper';
import { showErrorModal } from '../modal/reducer';
import { transformActions } from '../util';
import { ActionCreators } from './reducer';
import { getUserAuthState } from './selectors';

const staleInterval = ONE_MIN_IN_SEC * ONE_SEC_IN_MS;

const routerChangeEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) => {
  return action$.ofType('@@router/LOCATION_CHANGE').pipe(
    filter((action: LocationChangeAction) => {
      const pathname = action.payload.location.pathname.toLowerCase();
      return (
        !action.payload.isFirstRendering &&
        pathname.indexOf('/account') === 0 &&
        !!getUserAuthState(state$.value)
      );
    }),
    throttle(() => observableInterval(staleInterval)),
    switchMap(() => {
      return observableOf(
        ActionCreators.refetchUserFromState.started(
          getUserAuthState(state$.value)! || {}
        )
      );
    })
  );
};

const fetchUserInfoFromUrl = (action$: ActionsObservable<any>) => {
  return transformActions(
    action$,
    ActionCreators.fetchUserFromCode.started
  ).pipe(
    switchMap((action) => {
      const code = action.payload;
      return postBungieAuth({
        code,
        grant_type: 'authorization_code',
        client_id: config.BUNGIE_CLIENT_ID,
      }).pipe(
        mergeMap((result) => {
          const actions: Array<Action<any>> = [
            ActionCreators.fetchUserFromCode.done({ params: code, result }),
            replace('/account'),
          ];
          return observableFrom(actions);
        }),
        catchError((err) => {
          const actions: Array<Action<any>> = [
            ActionCreators.fetchUserFromCode.failed({
              params: code,
              error: err,
            }),
            showErrorModal({
              errorHeading: 'Login Error',
              errorMessage: err.response?.error?.message,
              showReport: true,
            }),
            replace('/account'),
          ];
          return observableFrom(actions);
        }),
        takeUntil(action$.ofType(ActionCreators.fetchUserFromCode.started))
      );
    })
  );
};

const fetchUserInfoFromState = (action$: ActionsObservable<any>) => {
  return transformActions(
    action$,
    ActionCreators.refetchUserFromState.started
  ).pipe(
    switchMap((action) => {
      const params = action.payload;
      return postBungieAuth({
        refresh_token: params.refresh_token,
        grant_type: 'refresh_token',
        client_id: config.BUNGIE_CLIENT_ID,
      }).pipe(
        map((result) =>
          ActionCreators.refetchUserFromState.done({ params, result })
        ),
        catchError((err) => {
          const actions: Array<Action<any>> = [
            ActionCreators.refetchUserFromState.failed({
              params,
              error: err,
            }),
            showErrorModal({
              errorHeading: 'Login Error',
              errorMessage: err.response?.error?.message,
              showReport: true,
            }),
          ];
          return observableFrom(actions);
        }),
        takeUntil(action$.ofType(ActionCreators.refetchUserFromState.started))
      );
    })
  );
};

const userEpic = combineEpics(
  fetchUserInfoFromUrl,
  fetchUserInfoFromState,
  routerChangeEpic
);

export default userEpic;
