import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { Action, ActionCreator } from 'typescript-fsa';

export const transformActions = <T>(
  action$: ActionsObservable<any>,
  actionCreator: ActionCreator<T>
): Observable<Action<T>> => {
  return action$.ofType(actionCreator.type);
};
