import { Component } from 'react';

interface IconProps {
  colored?: boolean;
  className?: string;
  width?: string;
  height?: string;
}
export default abstract class Icon extends Component<IconProps, {}> {
  protected viewbox = '0 0 1024 1024';

  protected abstract path: string;

  protected abstract defaultColor: string;

  public render() {
    const { width: pw, height: ph, className = '', colored } = this.props;
    const width = pw || ph || '1.45em';
    const height = ph || width;
    const fill = colored ? this.defaultColor : 'white';
    return (
      <svg
        className={className}
        fill={fill}
        width={width}
        height={height}
        viewBox={this.viewbox}
      >
        <path d={this.path} />
      </svg>
    );
  }
}
