import { FaEnvelope, FaTwitter } from 'react-icons/fa';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import { RootState } from '../store';
import './Footer.scss';

const year = new Date().getFullYear();

const footerTranslations = {
  copyrightText: `© ${year} DestinyRaidReport`,
  copyrightTextShort: `© ${year}`,
  settingsLink: 'Settings',
  privacyLink: 'Privacy',
  termsLink: 'Terms',
  faqLink: 'FAQ',
  raidReportLinkShort: 'DRR',
  raidReportLinkLong: 'Raid Report',
  versionLink: `v${process.env.REACT_APP_VERSION}`,
  contactUsText: 'Contact Us',
  email: 'Email',
  twitter: 'Twitter',
  developedBy: 'Developed by',
  and: 'and',
};

const createLink = (name, id) => <Link to={`/ps/${id}`}>{name}</Link>;

const developerLinks = (
  <div>
    {footerTranslations.developedBy}{' '}
    {createLink('RedSox007', '4611686018434095277')} {footerTranslations.and}{' '}
    {createLink('BlazeBoss', '4611686018433221963')}
  </div>
);
const emailLink = (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href="mailto:support@raid.report?subject=Dungeon%20Report%20Feedback&body=Make%20sure%20to%20include%20Gamertag%20and%20System%20if%20applicable"
  >
    <FaEnvelope className="footer-icon" />
    <span>{footerTranslations.email}</span>
  </a>
);
const twitterLink = (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href="https://twitter.com/RaidReport"
  >
    <FaTwitter className="footer-icon" />
    <span>{footerTranslations.twitter}</span>
  </a>
);

const mapStateToProps = (
  state: RootState,
  ownProps: RouteComponentProps<any>
) => ({
  pathName: ownProps.location.pathname,
});

type Props = ReturnType<typeof mapStateToProps>;

const Footer = ({ pathName }: Props) => {
  return (
    <footer className="drr-color page-footer">
      <div className="drr-container drr-footer">
        <div className="row small-bottom-margin raid-row">
          <div className="col s6">{developerLinks}</div>
          <div className="col s6">
            <div className="right">
              <span>{footerTranslations.contactUsText}</span>
              <ul className="no-margin small-top-margin">
                <li>{twitterLink}</li>
                <li>{emailLink}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="drr-container">
          <span className="footer-icon hide-on-small-only">
            {footerTranslations.copyrightText}
          </span>
          <span className="footer-icon hide-on-med-and-up">
            {footerTranslations.copyrightTextShort}
          </span>
          <Link to="/changelog">{footerTranslations.versionLink}</Link>
          <Link to="/settings" className="right">
            {footerTranslations.settingsLink}
          </Link>
          <Link to="/terms" className="right footer-icon">
            {footerTranslations.termsLink}
          </Link>
          <Link to="/privacy" className="right footer-icon">
            {footerTranslations.privacyLink}
          </Link>
          <Link to="/faq" className="right footer-icon">
            {footerTranslations.faqLink}
          </Link>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href={`https://raid.report${pathName}`}
            className="right footer-icon"
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            rel="noopener"
          >
            <span className="hide-on-small-only">
              {footerTranslations.raidReportLinkLong}
            </span>
            <span className="hide-on-med-and-up">
              {footerTranslations.raidReportLinkShort}
            </span>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default withRouter(connect(mapStateToProps)(Footer));
