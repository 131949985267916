import { RootState } from '..';

export const getSettings = (state: RootState) => state.settings;

export const getTheme = (state: RootState) => state.settings.theme;
export const getMobileLayout = (state: RootState) =>
  state.settings.mobileLayout || 'vertical';

export const getRaidFilter = (state: RootState) =>
  state.settings.filter || 'default';
