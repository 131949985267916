import { combineEpics } from 'redux-observable';
import leaderboardEpic from './leaderboard/epic';
import notificationEpic from './notification/epic';
import raidReportEpic from './raid-report/epic';
import sharedEpic from './shared/epic';
import userEpic from './user/epic';

export default combineEpics(
  leaderboardEpic,
  raidReportEpic,
  sharedEpic,
  notificationEpic,
  userEpic
);
