import { Button, makeStyles, Theme } from '@material-ui/core';
import { FaTwitter } from 'react-icons/fa';
import { IoMdMedal } from 'react-icons/io';
import { Link } from 'react-router-dom';

const translations = {
  follow: 'Follow us',
  premium: 'Premium',
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '-0.5rem',
  },
  twitter: {
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: '#1b95e0',
    textTransform: 'none',
    marginTop: '0.5rem',
    '&:hover, &:focus, &:active': {
      backgroundColor: '#0c7abf',
    },
  },
  premium: {
    color: theme.palette.common.white,
    textTransform: 'none',
    marginTop: '0.5rem',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const AllCard = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button
        color="primary"
        size="small"
        variant="contained"
        className={classes.twitter}
        href="https://twitter.com/intent/user?screen_name=RaidReport"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaTwitter className={classes.icon} />
        <span>{translations.follow}</span>
      </Button>
      <Button
        color="primary"
        size="small"
        variant="contained"
        className={classes.premium}
        component={Link}
        to="/account"
      >
        <IoMdMedal className={classes.icon} />
        <span>{translations.premium}</span>
      </Button>
    </div>
  );
};

export default AllCard;
