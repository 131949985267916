export enum SubscriptionTier {
  GOLD = 1,
  DIAMOND = 2,
}

export interface Price {
  amount: number;
  interval: 'month' | 'year';
}

export interface Subscription {
  tier: SubscriptionTier;
  features: {
    removesAds?: boolean;
    addsProfileBadge?: boolean;
    addsLeaderboardFlair?: boolean;
  };
  displayProperties: {
    title: string;
    color: string;
  };
  prices: Price[];
}
