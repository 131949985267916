import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getServiceWorker, register } from './serviceWorker';

interface Context {
  serviceWorkerRegistered?: boolean;
  updateAvailable?: boolean;
}

interface ServiceWorkerEvent extends Event {
  target: (Partial<ServiceWorker> & EventTarget) | null;
}

const ServiceWorkerContext = createContext<Context>({});

export const ServiceWorkerProvider = (props: { children?: ReactNode }) => {
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [serviceWorkerRegistered, setServiceWorkerRegistered] = useState(false);

  useEffect(() => {
    register({
      onUpdate: ({ waiting }) => {
        if (waiting) {
          setUpdateAvailable(true);
          waiting.addEventListener(
            'statechange',
            ({ target }: ServiceWorkerEvent) => {
              if (target?.state === 'activated') {
                setTimeout(() => {
                  window.location.hash = 'update';
                  window.location.reload();
                }, 50);
              }
            }
          );
          // immediately skip waiting
          waiting.postMessage({ type: 'SKIP_WAITING' });
        }
      },
      onSuccess: () => {
        setServiceWorkerRegistered(true);
      },
    });
    getServiceWorker().then((reg) => {
      setServiceWorkerRegistered(!!reg);
    });
  }, []);

  return (
    <ServiceWorkerContext.Provider
      value={{ serviceWorkerRegistered, updateAvailable }}
      {...props}
    />
  );
};

export const useServiceWorkerRegistered = () => {
  return useContext(ServiceWorkerContext).serviceWorkerRegistered;
};

export const useUpdateAvailable = () => {
  return useContext(ServiceWorkerContext).updateAvailable;
};
