import { ReactNode } from 'react';
import MetaTags from '../../components/MetaTags';
import {
  SITE_CARD_BACKGROUND_URL,
  SITE_CARD_EMBLEM_IMAGE,
} from '../../constants';
import DestinyCard from './DestinyCard';

interface Props {
  header: string;
  description?: string;
  children: ReactNode;
}

const StaticContent = ({ header, description, children }: Props) => {
  return (
    <div>
      <MetaTags title={header} description={description} />
      <DestinyCard
        emblemImage={SITE_CARD_EMBLEM_IMAGE}
        backgroundImage={SITE_CARD_BACKGROUND_URL}
      >
        <h1 className="static-card-title">{header}</h1>
      </DestinyCard>
      <div className="card">
        <div className="card-content">{children}</div>
      </div>
    </div>
  );
};

export default StaticContent;
