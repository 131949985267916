import {
  alpha,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { captureException, showReportDialog } from '@sentry/browser';
import { connect } from 'react-redux';
import BungiePrivacySteps from '../../../components/BungiePrivacySteps';
import {
  ErrorProps,
  hideModal as hideModalAction,
  ModalShowState,
} from '../../../store/modal/reducer';

const errorModalTranslations = {
  ok: 'OK',
  report: 'Report',
};

const dispatchToProps = {
  hideModal: hideModalAction,
};

type Props = ModalShowState & ErrorProps & typeof dispatchToProps;

const createAndReportSentryError = (
  message: string = 'No information available'
) => {
  const eventId = captureException(new Error(message));
  showReportDialog({ eventId });
};

const useStyles = makeStyles(({ palette: { error } }) => ({
  title: {
    borderTop: '4px solid',
    borderTopColor: error.main,
    backgroundColor: alpha(error.main, 0.1),
  },
}));

const ErrorModal = ({
  showModal,
  hideModal,
  errorHeading,
  errorMessage,
  showReport,
  privacyError,
}: Props) => {
  const classes = useStyles();
  return (
    <Dialog
      open={showModal}
      onClose={() => hideModal()}
      aria-labelledby="error-dialog-title"
    >
      <DialogTitle id="error-dialog-title" className={classes.title}>
        {errorHeading}
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="span">
          {errorMessage}
          {privacyError && <BungiePrivacySteps />}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {showReport && (
          <Button
            onClick={() => {
              createAndReportSentryError(errorMessage);
              hideModal();
            }}
            color="secondary"
          >
            {errorModalTranslations.report}
          </Button>
        )}
        <Button onClick={() => hideModal()} color="primary">
          {errorModalTranslations.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(null, dispatchToProps)(ErrorModal);
