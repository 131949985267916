import orderBy from 'lodash/orderBy';
import sortedUniqBy from 'lodash/sortedUniqBy';
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { AccountStatsResponse } from '../../types/account-stats';
import { AccountSummary } from '../../types/account-summary';
import { Activity, ActivityResponse } from '../../types/activity';
import { AggregateActivity } from '../../types/aggregate-activity';
import {
  CompleteMembershipRequest,
  MembershipInfo,
  MembershipsResponse,
} from '../../types/membership-info';
import { RaidReportStats } from '../../types/raid-report';
import { RANK_TIER } from '../../types/rank';

export interface RaidReportState {
  isLoading: boolean;
  fetchingAggregateActivities: boolean;
  aggregateActivities: AggregateActivity[];
  fetchingMembershipInfo: boolean;
  membershipInfo: MembershipInfo;
  fetchingAccountSummary: boolean;
  accountSummary: AccountSummary;
  fetchingAccountStats: boolean;
  accountStats: AccountStatsResponse;
  fetchingActivities: boolean;
  activities: Activity[];
  fetchingMemberships: boolean;
  destinyMemberships: MembershipInfo[];
  fetchingRaidReportStats: boolean;
  raidReportStats: RaidReportStats;
}

const initialState: RaidReportState = {
  isLoading: false,
  fetchingAggregateActivities: false,
  fetchingMembershipInfo: false,
  fetchingAccountSummary: false,
  fetchingAccountStats: false,
  fetchingActivities: false,
  fetchingMemberships: false,
  aggregateActivities: [],
  membershipInfo: {
    displayName: '',
    membershipId: '',
    membershipType: 0,
    iconPath: '',
  },
  accountSummary: {
    characters: {
      data: {},
      privacy: 0,
    },
    profile: {
      data: {
        userInfo: {
          displayName: '',
          membershipId: '',
          membershipType: 0,
          iconPath: '',
        },
        characterIds: [],
      },
      privacy: 0,
    },
    profileRecords: {
      data: undefined,
      privacy: 0,
    },
    characterRecords: {
      data: undefined,
      privacy: 0,
    },
  },
  activities: [],
  destinyMemberships: [],
  accountStats: {
    characters: [],
    mergedAllCharacters: {},
    mergedDeletedCharacters: {},
  },
  fetchingRaidReportStats: false,
  raidReportStats: {
    membershipId: '',
    activities: [],
    clearsRank: {
      value: 0,
      tier: RANK_TIER.UNRANKED,
    },
    speedRank: {
      value: 0,
      tier: RANK_TIER.UNRANKED,
    },
  },
};

enum Action {
  REQUEST_AGGREGATE_STATS,
  RECEIVE_AGGREGATE_STATS,
  REQUEST_MEMBERSHIP_INFO,
  RECEIVE_MEMBERSHIP_INFO,
  REQUEST_ACCOUNT_STATS,
  RECEIVE_ACCOUNT_STATS,
  REQUEST_ACCOUNT_SUMMARY,
  RECEIVE_ACCOUNT_SUMMARY,
  REQUEST_ACTIVITIES,
  RECEIVE_ACTIVITIES,
  REQUEST_MEMBERSHIPS,
  RECEIVE_MEMBERSHIPS,
  START_LOADING,
  FINISH_LOADING,
  SELECT_CURRENT_CHARACTERS,
  RAID_REPORT_STATS_REQUEST,
  HIDE_PRIVATE_DATA,
}

const actionCreator = actionCreatorFactory('RaidReport');

export const requestAccountStats = actionCreator<MembershipInfo>(
  Action[Action.REQUEST_ACCOUNT_STATS]
);
export const receiveAccountStats = actionCreator<AccountStatsResponse>(
  Action[Action.RECEIVE_ACCOUNT_STATS]
);

export const requestAggregateActivities =
  actionCreator<CompleteMembershipRequest>(
    Action[Action.REQUEST_AGGREGATE_STATS]
  );
export const receiveAggregateActivities = actionCreator<
  ActivityResponse<AggregateActivity>[]
>(Action[Action.RECEIVE_AGGREGATE_STATS]);

export const requestMembershipInfo = actionCreator<Partial<MembershipInfo>>(
  Action[Action.REQUEST_MEMBERSHIP_INFO]
);
export const receiveMembershipInfo = actionCreator<MembershipInfo>(
  Action[Action.RECEIVE_MEMBERSHIP_INFO]
);

export const requestAccountSummary = actionCreator<MembershipInfo>(
  Action[Action.REQUEST_ACCOUNT_SUMMARY]
);
export const receiveAccountSummary = actionCreator<AccountSummary>(
  Action[Action.RECEIVE_ACCOUNT_SUMMARY]
);

export const requestActivities = actionCreator<CompleteMembershipRequest>(
  Action[Action.REQUEST_ACTIVITIES]
);
export const receiveActivities = actionCreator<Activity[][]>(
  Action[Action.RECEIVE_ACTIVITIES]
);

export const requestMemberships = actionCreator<MembershipInfo>(
  Action[Action.REQUEST_MEMBERSHIPS]
);
export const receiveMemberships = actionCreator<MembershipsResponse>(
  Action[Action.RECEIVE_MEMBERSHIPS]
);

export const raidReportStatsRequest = actionCreator.async<
  MembershipInfo,
  RaidReportStats
>(Action[Action.RAID_REPORT_STATS_REQUEST]);

export const startLoading = actionCreator(Action[Action.START_LOADING]);
export const finishLoading = actionCreator(Action[Action.FINISH_LOADING]);

export const hidePrivateData = actionCreator(Action[Action.HIDE_PRIVATE_DATA]);

export const selectCurrentCharacters = actionCreator(
  Action[Action.SELECT_CURRENT_CHARACTERS]
);

export const ActionCreators = {
  requestAccountStats,
  receiveAccountStats,
  requestAggregateActivities,
  receiveAggregateActivities,
  requestMembershipInfo,
  receiveMembershipInfo,
  requestAccountSummary,
  receiveAccountSummary,
  requestActivities,
  receiveActivities,
  requestMemberships,
  receiveMemberships,
  raidReportStatsRequest,
  startLoading,
  finishLoading,
  hidePrivateData,
  selectCurrentCharacters,
};

export default reducerWithInitialState(initialState)
  .case(startLoading, (state, payload) => {
    return {
      ...state,
      isLoading: true,
      fetchingAccountStats: true,
      accountStats: initialState.accountStats,
      fetchingActivities: true,
      activities: initialState.activities,
      fetchingAccountSummary: true,
      accountSummary: initialState.accountSummary,
      fetchingAggregateActivities: true,
      aggregateActivities: initialState.aggregateActivities,
      fetchingRaidReportStats: true,
      raidReportStats: initialState.raidReportStats,
    };
  })
  .case(finishLoading, (state, payload) => {
    return { ...state, isLoading: false };
  })
  .case(requestAggregateActivities, (state, payload) => {
    return {
      ...state,
      aggregateActivities: initialState.aggregateActivities,
      fetchingAggregateActivities: true,
    };
  })
  .case(requestMembershipInfo, (state, payload) => {
    return { ...state, fetchingMembershipInfo: true };
  })
  .case(requestMemberships, (state, payload) => {
    return {
      ...state,
      destinyMemberships: initialState.destinyMemberships,
      fetchingMemberships: true,
    };
  })
  .case(requestAccountStats, (state, payload) => {
    return {
      ...state,
      accountStats: initialState.accountStats,
      fetchingAccountStats: true,
    };
  })
  .case(requestAccountSummary, (state, payload) => {
    return {
      ...state,
      accountSummary: initialState.accountSummary,
      fetchingAccountSummary: true,
    };
  })
  .case(requestActivities, (state, payload) => {
    return {
      ...state,
      activities: initialState.activities,
      fetchingActivities: true,
    };
  })
  .case(raidReportStatsRequest.started, (state, payload) => {
    return {
      ...state,
      raidReportStats: initialState.raidReportStats,
      fetchingRaidReportStats: true,
    };
  })
  .case(receiveAggregateActivities, (state, payload) => {
    return {
      ...state,
      fetchingAggregateActivities: false,
      aggregateActivities: payload
        .map(
          (d) =>
            (d.activities && d.activities.filter((a) => a.activityHash)) || []
        )
        .reduce((a, b) => a.concat(b), []),
    };
  })
  .case(receiveMembershipInfo, (state, membershipInfo) => {
    return {
      ...initialState,
      membershipInfo,
      isLoading: state.isLoading,
      destinyMemberships: state.destinyMemberships,
      fetchingMembershipInfo: false,
      fetchingAccountStats: true,
      fetchingAccountSummary: true,
      fetchingActivities: true,
      fetchingAggregateActivities: true,
      fetchingRaidReportStats: true,
    };
  })
  .case(receiveMemberships, (state, payload) => {
    return {
      ...state,
      fetchingMemberships: false,
      destinyMemberships: payload.destinyMemberships,
    };
  })
  .case(receiveAccountSummary, (state, accountSummary) => {
    return { ...state, accountSummary, fetchingAccountSummary: false };
  })
  .case(receiveAccountStats, (state, accountStats) => {
    return { ...state, accountStats, fetchingAccountStats: false };
  })
  .case(raidReportStatsRequest.done, (state, payload) => {
    return {
      ...state,
      raidReportStats: payload.result,
      fetchingRaidReportStats: false,
    };
  })
  .case(raidReportStatsRequest.failed, (state, payload) => {
    return {
      ...state,
      raidReportStats: initialState.raidReportStats,
      fetchingRaidReportStats: false,
    };
  })
  .case(receiveActivities, (state, payload) => {
    const activities: Activity[] = sortedUniqBy(
      orderBy(
        payload.reduce((a, b) => a.concat(b), []),
        [
          'activityDetails.instanceId',
          'values.completed.basic.value',
          'values.completionReason.basic.value',
          'values.kills.basic.value',
        ],
        ['asc', 'desc', 'asc', 'desc']
      ),
      'activityDetails.instanceId'
    );
    return { ...state, activities, fetchingActivities: false };
  })
  .case(hidePrivateData, (state, payload) => {
    return {
      ...initialState,
    };
  });
