import { stringify } from 'querystring';
import { Observable } from 'rxjs';
import { ajax, AjaxRequest } from 'rxjs/ajax';
import { map } from 'rxjs/operators';

export const createRequest = <T>(
  path: Array<string | number>,
  qs: {} | undefined = undefined,
  ajaxSettings: AjaxRequest = {},
  trailingSlash: boolean = false
): Observable<T> => {
  let url = path.join('/');
  if (trailingSlash) {
    url += '/';
  }
  if (qs) {
    url += `?${stringify(qs)}`;
  }
  return ajax({ ...ajaxSettings, url }).pipe(
    map((ajaxResponse) => ajaxResponse.response)
  );
};
