import { useEffect, useState } from 'react';
import LoadingBar from './LoadingBar';

const DelayedLoadingBar = () => {
  const [pastDelay, setPastDelay] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setPastDelay(true), 200);
    return () => clearTimeout(timeout);
  }, []);

  return pastDelay ? <LoadingBar /> : null;
};

export default DelayedLoadingBar;
