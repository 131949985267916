import { Link } from 'react-router-dom';
import {
  MATERIAL_ICONS,
  SITE_HEADER_IMAGE,
  SITE_NAME,
  SITE_SHORT_NAME,
} from '../../constants';
import { useSingleColumn } from '../../utils/hooks';
import { ChildSearchProps } from '../SearchContainer';
import AccountLink from './AccountLink';
import './Header.scss';

const headerTranslation = {
  searchPlaceholder: 'Search for a Guardian...',
  searchPlaceholderMobile: 'Search...',
};

const Header = ({
  refHandlers,
  handleKeyUp,
  handleClick,
}: ChildSearchProps) => {
  const smallScreen = useSingleColumn();
  const placeholder = smallScreen
    ? headerTranslation.searchPlaceholderMobile
    : headerTranslation.searchPlaceholder;
  return (
    <header>
      <nav className="drr-color">
        <div className="nav-wrapper drr-container">
          <div className="site-logo-name">
            <Link to="/">
              <img src={SITE_HEADER_IMAGE} alt="" />
              <span>{smallScreen ? SITE_SHORT_NAME : SITE_NAME}</span>
            </Link>
          </div>
          <div className="search-bar input-field">
            <input
              ref={refHandlers.search}
              id="search"
              type="search"
              onKeyUp={handleKeyUp}
              placeholder={placeholder}
              aria-label={headerTranslation.searchPlaceholder}
            />
            <label className="label-icon">
              <i className="material-icons">{MATERIAL_ICONS.search}</i>
            </label>
            <i
              className="material-icons search-button"
              tabIndex={0}
              role="button"
              onClick={handleClick}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  handleClick();
                }
              }}
            >
              {MATERIAL_ICONS.send}
            </i>
          </div>
          <AccountLink />
        </div>
      </nav>
    </header>
  );
};

export default Header;
