import { connect } from 'react-redux';
import { requestMembershipInfo } from '../store/raid-report/reducer';
import Header from './components/Header';
import SearchContainer from './SearchContainer';

class HeaderContainer extends SearchContainer {
  protected Child = Header;

  protected requestMembershipInfo = requestMembershipInfo;
}

export default connect()(HeaderContainer);
