const BungiePrivacySteps = () => {
  return (
    <div className="small-top-margin">
      To update your privacy settings follow these steps:
      <ul className="no-margin">
        <li>
          Sign in to{' '}
          <a
            href="https://bungie.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            bungie.net
          </a>{' '}
          (Not the app)
        </li>
        <li>Go to your Settings page</li>
        <li>Go to the Privacy tab</li>
        <li>Check the following:</li>
        <li>&quot;Show my Destiny game Activity Feed on Bungie.net&quot;</li>
        <li>
          &quot;Show my Progression (what I&apos;ve completed in Destiny, and my
          current status)&quot;
        </li>
      </ul>
    </div>
  );
};

export default BungiePrivacySteps;
