import { useMediaQuery } from '@material-ui/core';

export const useTouchDevice = () => {
  return useMediaQuery('(hover: none)');
};

export const useSingleColumn = () => {
  return useMediaQuery('(max-width: 600px)');
};

export const useFourColumns = () => {
  return useMediaQuery('(min-width: 993px)');
};
