import { push } from 'connected-react-router';
import { stringify } from 'querystring';
import {
  ActionsObservable,
  combineEpics,
  StateObservable,
} from 'redux-observable';
import { of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RootState } from '..';
import { LeaderboardQueryStrings } from '../../types/leaderboard';
import { parseLeaderboardQueryString } from '../../utils/leaderboard';
import { ActionCreators as SharedActionCreators } from '../shared/reducer';
import { transformActions } from '../util';
import { ActionCreators } from './reducer';

const fetchMembershipInfo = (action$: ActionsObservable<any>) => {
  return transformActions(action$, ActionCreators.requestMembershipInfo).pipe(
    switchMap((action) => {
      const membershipRequest = {
        ...action.payload,
        receiveMembershipInfoType: ActionCreators.receiveMembershipInfo.type,
        completeMembershipInfoType: '',
      };
      return observableOf(
        SharedActionCreators.requestMembershipInfo(membershipRequest)
      );
    })
  );
};

const receiveMembershipInfo = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) => {
  return transformActions(action$, ActionCreators.receiveMembershipInfo).pipe(
    switchMap((action) => {
      const { search, pathname } = state$.value.router.location;
      const { pageSize, membershipType } = parseLeaderboardQueryString(search);
      const queryString: LeaderboardQueryStrings = {
        membershipId: action.payload.membershipId,
      };
      if (pageSize) {
        queryString.pageSize = pageSize;
      }
      if (membershipType) {
        queryString.membershipType = membershipType;
      }
      return observableOf(
        push({ pathname, search: `?${stringify(queryString)}` })
      );
    })
  );
};

const leaderboardEpic = combineEpics(
  fetchMembershipInfo,
  receiveMembershipInfo
);

export default leaderboardEpic;
