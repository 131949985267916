export interface BasicValue {
  basic: {
    value: number;
    displayValue: string;
  };
}

export const EmptyBasicValue: BasicValue = {
  basic: {
    value: 0,
    displayValue: '0',
  },
};

export const EmptyBasicTimeValue: BasicValue = {
  basic: {
    value: 0,
    displayValue: '0m 0s',
  },
};

export interface BungieResponse<T> {
  Response: T;
  ErrorCode: number;
  ThrottleSeconds: number;
  ErrorStatus: string;
  Message: string;
  MessageData: any;
}

export interface BaseActivityDetails {
  instanceId: string;
}
