import { SUBSCRIPTION_MAP } from '../constants/subscription';
import { StripeSubscription } from '../types/stripe';
import { Subscription } from '../types/subscription';

export const mergeFeatures = (
  features: Array<Subscription['features'] | undefined> | undefined
) => {
  return (
    features?.reduce((prev = {}, curr = {}) => {
      const next = { ...prev };
      Object.keys(curr).forEach((key) => {
        next[key] = prev[key] || curr[key];
      });
      return next;
    }, {}) || {}
  );
};

export const getFeaturesFromStripe = (
  subscriptions: Array<StripeSubscription> | undefined
) => {
  return mergeFeatures(
    subscriptions?.map((sub) => SUBSCRIPTION_MAP[sub.tier]?.features)
  );
};
