import { Button, Grid, Typography } from '@material-ui/core';
import { captureException, showReportDialog } from '@sentry/browser';
import { Component } from 'react';
import { MdBugReport, MdRefresh, MdRemoveCircle } from 'react-icons/md';
import AppLayout from '../AppLayout';
import StaticContent from '../layouts/components/StaticContent';
import { unregister } from '../serviceWorker';
import './ErrorBoundary.scss';

class ErrorBoundary extends Component<{}, { error?: any }> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;
    if (error) {
      return (
        <AppLayout>
          <StaticContent header="Something went wrong">
            <Typography style={{ marginBottom: '1rem' }}>
              If the problem persists after reloading, please report the issue.
            </Typography>
            <Grid container spacing={1} className="dir-small-col">
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  className="reload-button"
                  startIcon={<MdRefresh />}
                  onClick={() => window.location.reload()}
                >
                  Reload
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<MdRemoveCircle />}
                  onClick={() => {
                    unregister().then(() => {
                      window.location.reload();
                    });
                  }}
                >
                  Force reload
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  variant="outlined"
                  startIcon={<MdBugReport />}
                  onClick={() => {
                    const eventId = captureException(error);
                    showReportDialog({ eventId });
                  }}
                >
                  Report
                </Button>
              </Grid>
            </Grid>
          </StaticContent>
        </AppLayout>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
