import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import modal, { ModalState } from './modal/reducer';
import notification, {
  NotificationState,
  notificationTransform,
} from './notification/reducer';
import raidReport, { RaidReportState } from './raid-report/reducer';
import settings, { SettingsState } from './settings/reducer';
import shared, { SharedState } from './shared/reducer';
import user, { UserState, userTransform } from './user/reducer';

export interface DRRState {
  modal: ModalState;
  raidReport: RaidReportState;
  shared: SharedState;
  settings: SettingsState;
  notification: NotificationState;
  user: UserState;
}

export const transforms = [notificationTransform, userTransform];

export default (history: History<any>) =>
  combineReducers({
    modal,
    raidReport,
    shared,
    settings,
    notification,
    user,
    router: connectRouter(history),
  });
