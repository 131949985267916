import { useQuery } from 'react-query';
import { getLinkedProfiles } from '../services/bungie/destiny';

export const useLinkedProfilesQuery = (bungieId?: string) => {
  return useQuery(
    ['linkedProfiles', bungieId],
    () => getLinkedProfiles(bungieId!).toPromise(),
    { staleTime: Infinity, enabled: !!bungieId }
  );
};
