import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { SITE_META_NAME } from '../constants';

interface Props {
  title: string;
  description?: string;
  shortDescription?: string;
  addSuffix?: boolean;
  children?: ReactNode;
}

const MetaTags = ({
  title,
  description,
  shortDescription,
  addSuffix = true,
  children,
}: Props) => {
  const fullTitle = addSuffix ? `${title} | ${SITE_META_NAME}` : title;
  const ogDescription = shortDescription || description;
  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta property="og:title" content={fullTitle} />
      {description && <meta name="description" content={description} />}
      {ogDescription && (
        <meta property="og:description" content={ogDescription} />
      )}
      {children}
    </Helmet>
  );
};

export default MetaTags;
