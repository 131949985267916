import { useMediaQuery } from '@material-ui/core';
import random from 'lodash/random';
import { nanoid } from 'nanoid';
import { CSSProperties, useState } from 'react';
import { SUBSCRIPTIONS } from '../../../constants/subscription';
import { useSingleColumn } from '../../../utils/hooks';
import './Sparkles.scss';
import { useInterval } from './useInterval';

const getRandomDirectionAndNumber = () => {
  const value = random(0, 100);
  const dir = value > 50;
  return { dir, value: dir ? value % 50 : value };
};
const generateSparkle = () => {
  const colorIndex = random(SUBSCRIPTIONS.length - 1);
  const { color } = SUBSCRIPTIONS[colorIndex].displayProperties;
  const { dir: xDir, value: xValue } = getRandomDirectionAndNumber();
  const { dir: yDir, value: yValue } = getRandomDirectionAndNumber();
  const sparkle = {
    id: nanoid(),
    createdAt: Date.now(),
    color,
    size: random(15, 20),
    style: {
      [xDir ? 'left' : 'right']: `${xValue}%`,
      [yDir ? 'top' : 'bottom']: `${yValue}%`,
    },
  };
  return sparkle;
};

const Sparkles = () => {
  const prefersReducedMotion = !useMediaQuery(
    '(prefers-reduced-motion: no-preference)'
  );
  const [sparkles, setSparkles] = useState(() => {
    return [...Array(prefersReducedMotion ? 7 : 0)].map(generateSparkle);
  });
  const [shown, setShown] = useState(sparkles.length);
  const freeze = shown > 100 || prefersReducedMotion;
  const isSmall = useSingleColumn();
  useInterval(
    () => {
      const sparkle = generateSparkle();
      const now = Date.now();
      const nextSparkles = sparkles.filter((sp) => {
        const delta = now - sp.createdAt;
        return delta < 1000;
      });
      nextSparkles.push(sparkle);
      setSparkles(nextSparkles);
      setShown((s) => s + 1);
    },
    // eslint-disable-next-line no-nested-ternary
    freeze ? undefined : isSmall ? 150 : 100
  );
  return (
    <>
      {sparkles.map((sparkle) => (
        <Sparkle
          key={sparkle.id}
          color={sparkle.color}
          size={sparkle.size}
          style={sparkle.style}
          hide={!freeze}
        />
      ))}
    </>
  );
};

const Sparkle = ({
  size,
  color,
  style,
  hide = true,
}: {
  size: number;
  color: string;
  style: CSSProperties;
  hide?: boolean;
}) => {
  const path =
    'M26.5 25.5C19.0043 33.3697 0 34 0 34C0 34 19.1013 35.3684 26.5 43.5C33.234 50.901 34 68 34 68C34 68 36.9884 50.7065 44.5 43.5C51.6431 36.647 68 34 68 34C68 34 51.6947 32.0939 44.5 25.5C36.5605 18.2235 34 0 34 0C34 0 33.6591 17.9837 26.5 25.5Z';
  let wrapperClass = 'sparkle-wrapper';
  if (!hide) {
    wrapperClass += ' iteration-count-1';
  }
  return (
    <span style={style} className={wrapperClass}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 68 68"
        fill={color}
        className="sparkle-svg"
      >
        <path d={path} />
      </svg>
    </span>
  );
};

export default Sparkles;
