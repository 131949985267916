import EpicIcon from '../components/EpicIcon';
import Icon from '../components/Icon';
import PCIcon from '../components/PCIcon';
import PSIcon from '../components/PSIcon';
import StadiaIcon from '../components/StadiaIcon';
import XboxIcon from '../components/XboxIcon';
import CLEARS_ICON from '../images/clears_icon.png';
import SITE_CARD_BACKGROUND_IMAGE from '../images/raid_background.jpg';
import SITE_CARD_EMBLEM_IMAGE from '../images/raid_emblem.jpg';
import SITE_HEADER_IMAGE from '../images/raid_icon_white.png';

export {
  SITE_CARD_EMBLEM_IMAGE,
  SITE_CARD_BACKGROUND_IMAGE,
  SITE_HEADER_IMAGE,
  CLEARS_ICON,
};

export const BUNGIE_URL = 'https://www.bungie.net';
export const BUNGIE_IMAGE_PATH = '/common/destiny2_content/icons/';
export const BUNGIE_FALLBACK_IMAGE_PATH =
  '/img/theme/destiny/icons/missing_emblem.jpg';
export const SITE_NAME = 'Dungeon Report';
export const SITE_META_NAME = 'Dungeon Report';
export const SITE_SHORT_NAME = 'DR';
export const SITE_CARD_BACKGROUND_URL = `url(${SITE_CARD_BACKGROUND_IMAGE})`;

export const STEAM_ID_REGEX = new RegExp('^\\d{17}$');
export const MEMBERSHIP_ID_REGEX = new RegExp('^\\d{19}$');

export const RECENT_RAIDS_HEIGHT = 50;

export const MATERIAL_ICONS = {
  cancel: 'cancel',
  check_circle: 'check_circle',
  close: 'close',
  error: 'error',
  search: 'search',
  send: 'send',
};

export const SUCCESS_COLOR = '#4CAF50';
export const ERROR_COLOR = '#F44336';

export const MEMBERSHIP_TYPE_MAP: {
  [key: string]: {
    label: string;
    displayName: string;
    displayColor?: string;
    icon: typeof Icon;
  };
} = {
  1: {
    label: 'Xbox',
    displayName: 'xb',
    displayColor: 'xbox-green',
    icon: XboxIcon,
  },
  2: {
    label: 'PlayStation',
    displayName: 'ps',
    displayColor: 'ps-blue',
    icon: PSIcon,
  },
  3: {
    label: 'PC',
    displayName: 'pc',
    displayColor: 'pc-blue',
    icon: PCIcon,
  },
  4: {
    label: 'PC',
    displayName: 'pc',
    displayColor: 'pc-blue',
    icon: PCIcon,
  },
  5: {
    label: 'Stadia',
    displayName: 'stadia',
    displayColor: 'stadia-orange',
    icon: StadiaIcon,
  },
  6: {
    label: 'Epic Games',
    displayName: 'epic',
    icon: EpicIcon,
  },
};

export const REVERSE_MEMBERSHIP_TYPE_MAP = {
  xb: 1,
  ps: 2,
  pc: 3,
  stadia: 5,
  epic: 6,
};

export const BUNGIE_REVERSE_MEMBERSHIP_TYPE_MAP = {
  TigerXbox: 1,
  TigerPsn: 2,
  TigerSteam: 3,
  TigerBlizzard: 4,
  TigerStadia: 5,
  TigerEgs: 6,
};

export const ALL_MEMBERSHIP_TYPES: number[] = Object.keys(
  REVERSE_MEMBERSHIP_TYPE_MAP
).map((key) => REVERSE_MEMBERSHIP_TYPE_MAP[key]);
