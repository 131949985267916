import uniqBy from 'lodash/uniqBy';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { from as observableFrom, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import actionCreatorFactory from 'typescript-fsa';
import { MEMBERSHIP_ID_REGEX, STEAM_ID_REGEX } from '../../constants';
import {
  getMembershipInfo,
  getMilestones,
} from '../../services/bungie/destiny';
import {
  getMembershipFromSteamId,
  searchForBungieUser,
} from '../../services/bungie/user';
import { searchRaidReportPlayers } from '../../services/raid-report/search';
import { MembershipInfo } from '../../types/membership-info';
import { RaidReportSearchResult } from '../../types/raid-report';
import { showErrorModal, showSelectorModal } from '../modal/reducer';
import { transformActions } from '../util';
import { ActionCreators } from './reducer';

const searchForPlayer = async (
  displayName: string,
  membershipType: number | undefined
) => {
  const rrRequest = searchRaidReportPlayers(displayName).toPromise();
  const destinyRequest = getMembershipInfo(displayName).toPromise();
  const bungieSearchRequest = searchForBungieUser(displayName).toPromise();

  let membershipInfos: (MembershipInfo | RaidReportSearchResult)[] =
    await rrRequest.catch(() => []);

  if (!membershipInfos.length) {
    const destinyResults = await destinyRequest;
    const bungieSearchResult = await bungieSearchRequest;
    const bungieResults = bungieSearchResult.searchResults
      .map((r) => r.destinyMemberships)
      .reduce((a, b) => a.concat(b), []);
    membershipInfos = uniqBy(
      bungieResults.concat(destinyResults),
      (info) => info.membershipId
    ).filter((r) => r.applicableMembershipTypes?.length !== 0);
  } else {
    // silently catch
    destinyRequest.catch(() => {});
    bungieSearchRequest.catch(() => {});
  }

  if (membershipType !== undefined) {
    const filteredMembershipInfos = membershipInfos.filter(
      (m) => m.membershipType === membershipType
    );
    if (filteredMembershipInfos.length) {
      membershipInfos = filteredMembershipInfos;
    }
  }

  if (membershipInfos.length > 1) {
    const filteredMembershipInfos = membershipInfos.filter(
      (m) => (m as RaidReportSearchResult).fullDungeonClears
    );
    if (filteredMembershipInfos.length) {
      membershipInfos = filteredMembershipInfos;
    }
  }
  return membershipInfos;
};

const fetchSharedMembershipInfoEpic = (action$: ActionsObservable<any>) => {
  return transformActions(action$, ActionCreators.requestMembershipInfo).pipe(
    switchMap((action) => {
      const displayName = action.payload.displayName || '';
      const {
        receiveMembershipInfoType,
        completeMembershipInfoType,
        membershipType,
      } = action.payload;
      const receiveMembershipInfo = actionCreatorFactory()<MembershipInfo>(
        receiveMembershipInfoType
      );
      const completeMembershipInfo = actionCreatorFactory()(
        completeMembershipInfoType
      );
      if (MEMBERSHIP_ID_REGEX.test(displayName)) {
        const membershipInfo: MembershipInfo = {
          membershipType: membershipType || 3,
          displayName: '',
          membershipId: displayName,
          iconPath: '',
        };
        return observableOf(receiveMembershipInfo(membershipInfo));
      }

      if (STEAM_ID_REGEX.test(displayName)) {
        return getMembershipFromSteamId(displayName).pipe(
          mergeMap((response) =>
            observableOf(
              receiveMembershipInfo({
                displayName: '',
                iconPath: '',
                ...response,
              })
            )
          ),
          catchError((err) =>
            observableOf(
              completeMembershipInfo(),
              showErrorModal({
                errorHeading: 'Bungie Error',
                errorMessage: err.Message,
              })
            )
          )
        );
      }

      return observableFrom(searchForPlayer(displayName, membershipType)).pipe(
        mergeMap((responses) => {
          if (!responses.length) {
            return observableOf(
              completeMembershipInfo(),
              showErrorModal({
                errorHeading: 'Error',
                errorMessage: `No Destiny user could be found with name: ${displayName}`,
              })
            );
          }
          return responses.length === 1
            ? observableOf(receiveMembershipInfo(responses[0]))
            : observableOf(
                completeMembershipInfo(),
                showSelectorModal({
                  membershipInfos: responses,
                  receiveMembershipInfoType,
                })
              );
        }),
        catchError((err) =>
          observableOf(
            completeMembershipInfo(),
            showErrorModal({
              errorHeading: 'Bungie Error',
              errorMessage: err.Message,
            })
          )
        )
      );
    })
  );
};

const fetchMilestonesEpic = (action$: ActionsObservable<any>) => {
  return transformActions(action$, ActionCreators.requestMilestones).pipe(
    switchMap(() => {
      return getMilestones().pipe(
        map((response) => ActionCreators.receiveMilestones(response)),
        catchError((err) =>
          observableOf(
            showErrorModal({
              errorHeading: 'Bungie Error',
              errorMessage: err.Message,
            }),
            ActionCreators.failureMilestones()
          )
        )
      );
    })
  );
};

const sharedEpic = combineEpics(
  fetchSharedMembershipInfoEpic,
  fetchMilestonesEpic
);

export default sharedEpic;
