import { BasicValue, EmptyBasicTimeValue, EmptyBasicValue } from './shared';

export interface AggregateActivity {
  activityHash: number;
  values: AggregateActivityValues;
}

export interface AggregateActivityValues {
  fastestCompletionMsForActivity: BasicValue;
  activityCompletions: BasicValue;
  activityDeaths: BasicValue;
  activityKills: BasicValue;
  activitySecondsPlayed: BasicValue;
  activityAssists: BasicValue;
}

export const EmptyAggregateActivityValues: AggregateActivityValues = {
  fastestCompletionMsForActivity: EmptyBasicTimeValue,
  activityAssists: EmptyBasicValue,
  activityCompletions: EmptyBasicValue,
  activityDeaths: EmptyBasicValue,
  activityKills: EmptyBasicValue,
  activitySecondsPlayed: EmptyBasicTimeValue,
};

export const EmptyAggregateActivity: AggregateActivity = {
  activityHash: NaN,
  values: EmptyAggregateActivityValues,
};
