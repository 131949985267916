export interface RankInformation {
  backgroundColor: string;
  tooltip: string;
}

export interface Rank {
  value: number;
  tier: RANK_TIER;
  subtier?: RANK_SUBTIER;
  rank?: number;
}

export enum RANK_TIER {
  UNRANKED = 'Unranked',
  BRONZE = 'Bronze',
  SILVER = 'Silver',
  GOLD = 'Gold',
  PLATINUM = 'Platinum',
  DIAMOND = 'Diamond',
  MASTER = 'Master',
  CHALLENGER = 'Challenger',
}

export enum RANK_SUBTIER {
  I = 'I',
  II = 'II',
  III = 'III',
  IV = 'IV',
  V = 'V',
}
