import {
  Avatar,
  Button,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BUNGIE_URL } from '../../constants';
import { useLinkedProfilesQuery } from '../../queries/useLinkedProfilesQuery';
import { RootState } from '../../store';
import { useRehydrated } from '../../store/db';
import { dismissUserTooltip } from '../../store/user/reducer';
import { getUserAuthState } from '../../store/user/selectors';

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    marginLeft: '4px',
  },
  accountIcon: {
    color: 'white',
    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.08)' },
    '@media only screen and (max-width: 600px)': {
      minWidth: '42px',
    },
  },
  tooltip: {
    marginTop: '8px',
  },
  avatar: {
    width: '30px',
    height: '30px',
    '@media only screen and (max-width: 600px)': {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
  avatarColors: {
    color: theme.palette.primary.main,
    backgroundColor: 'white',
  },
}));

const AccountLink = () => {
  const { closeIcon, accountIcon, tooltip, avatar, avatarColors } = useStyles();

  const rehydrated = useRehydrated();
  const open = useSelector(
    (state: RootState) => !state.user.hasDismissedTooltip
  );
  const dispatch = useDispatch();
  const close = () => {
    if (open) {
      dispatch(dismissUserTooltip());
    }
  };
  const authState = useSelector(getUserAuthState);
  const { data, isLoading } = useLinkedProfilesQuery(authState?.membership_id);
  const emblemPath = data?.bnetMembership.iconPath;

  const avatarComponent = (
    <Avatar
      classes={{ root: avatar, colorDefault: avatarColors }}
      src={emblemPath && `${BUNGIE_URL}${emblemPath}`}
    />
  );
  return (
    <Tooltip
      open={open && rehydrated}
      arrow
      interactive
      classes={{ tooltipPlacementBottom: tooltip }}
      title={
        <Typography>
          Join Raid Report Premium
          <IconButton
            onClick={close}
            size="small"
            className={closeIcon}
            color="inherit"
          >
            <MdClose />
          </IconButton>
        </Typography>
      }
    >
      <Button
        component={Link}
        to="/account"
        className={accountIcon}
        onClick={close}
        aria-label="Account"
      >
        {isLoading || !rehydrated ? (
          <Skeleton variant="circle">{avatarComponent}</Skeleton>
        ) : (
          avatarComponent
        )}
      </Button>
    </Tooltip>
  );
};

export default AccountLink;
