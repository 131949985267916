import {
  LeaderboardQueryStrings,
  LeaderboardRequestMetadata,
  LeaderboardResponse,
} from '../../types/leaderboard';
import { MembershipInfo } from '../../types/membership-info';
import { RaidReportStats } from '../../types/raid-report';
import { createRaidReportRequest } from './util';

export const getRaidReportStats = (player: MembershipInfo) => {
  const path = ['player', player.membershipId];
  return createRaidReportRequest<RaidReportStats>(path);
};

export const getRaidReportLeaderboards = (
  request: LeaderboardRequestMetadata
) => {
  const { page, pageSize, membershipId, type, raid, version, membershipType } =
    request;
  const path = ['leaderboard', type, raid];
  if (version) {
    path.push(version);
  }
  const qs: LeaderboardQueryStrings = membershipId
    ? { membershipId }
    : { page };
  qs.pageSize = pageSize;
  if (membershipType) {
    qs.membershipType = membershipType;
  }
  return createRaidReportRequest<LeaderboardResponse>(path, qs).toPromise();
};
