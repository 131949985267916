import { push } from 'connected-react-router';
import {
  ActionsObservable,
  combineEpics,
  StateObservable,
} from 'redux-observable';
import {
  forkJoin as observableForkJoin,
  from as observableFrom,
  merge as observableMerge,
  of as observableOf,
  zip as observableZip,
} from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { Action } from 'typescript-fsa';
import { RootState } from '..';
import { BUNGIE_REVERSE_MEMBERSHIP_TYPE_MAP } from '../../constants';
import {
  getAccountStats,
  getAccountSummary,
  getPlayerActivities,
  getPlayerAggregateActivityStats,
} from '../../services/bungie/destiny';
import { getMembershipsById } from '../../services/bungie/user';
import { getRaidReportStats } from '../../services/raid-report/service';
import { getPathForMembershipInfo } from '../../utils/app-helper';
import { showErrorModal } from '../modal/reducer';
import { ActionCreators as SharedActionCreators } from '../shared/reducer';
import { transformActions } from '../util';
import { ActionCreators } from './reducer';
import {
  getCompleteMembershipRequest,
  getCompleteMembershipRequestWithFailure,
  getDestinyMemberships,
} from './selectors';

const combineErrorActionAndStopLoading = (
  errorHeading: string,
  errorMessage: string,
  privacyError?: boolean
) => {
  return observableOf(
    ActionCreators.finishLoading(),
    showErrorModal({ errorHeading, errorMessage, privacyError })
  );
};

const fetchMembershipInfoEpic = (action$: ActionsObservable<any>) => {
  return transformActions(action$, ActionCreators.requestMembershipInfo).pipe(
    switchMap((action) => {
      const membershipRequest = {
        ...action.payload,
        receiveMembershipInfoType: ActionCreators.receiveMembershipInfo.type,
        completeMembershipInfoType: ActionCreators.finishLoading.type,
      };
      return observableOf(
        SharedActionCreators.requestMembershipInfo(membershipRequest)
      );
    })
  );
};

const receiveMembershipInfoEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) => {
  return transformActions(action$, ActionCreators.receiveMembershipInfo).pipe(
    mergeMap((action) => {
      const actions: Array<Action<any>> = [
        ActionCreators.startLoading(),
        ActionCreators.requestAccountStats(action.payload),
        ActionCreators.requestAccountSummary(action.payload),
        ActionCreators.raidReportStatsRequest.started(action.payload),
      ];
      const newPath = getPathForMembershipInfo(action.payload);
      if (
        newPath.toLowerCase() !==
        state$.value.router.location.pathname.toLowerCase()
      ) {
        actions.push(push(newPath));
      }
      if (
        !getDestinyMemberships(state$.value).some(
          (info) => info.membershipId === action.payload.membershipId
        )
      ) {
        actions.push(ActionCreators.requestMemberships(action.payload));
      }
      return observableFrom(actions);
    })
  );
};

const ACCOUNT_STAT_FAILURE_ERROR_MESSAGE =
  'Incomplete character information was returned by Bungie. Attempting to use current character information';

const fetchAccountStatsEpic = (action$: ActionsObservable<any>) => {
  return transformActions(action$, ActionCreators.requestAccountStats).pipe(
    switchMap((action) => {
      return getAccountStats(action.payload).pipe(
        map((response) => ActionCreators.receiveAccountStats(response)),
        catchError((err) => {
          if (err.status === 0 || err.ErrorCode === 1618) {
            return observableOf(
              ActionCreators.selectCurrentCharacters(),
              showErrorModal({
                errorHeading: 'Bungie Error',
                errorMessage: ACCOUNT_STAT_FAILURE_ERROR_MESSAGE,
              })
            );
          }
          if (err.ErrorCode === 18 && action.payload.membershipType === 3) {
            return observableOf(
              ActionCreators.receiveMembershipInfo({
                ...action.payload,
                membershipType: 4,
              })
            );
          }
          return combineErrorActionAndStopLoading('Bungie Error', err.Message);
        }),
        takeUntil(action$.ofType(ActionCreators.receiveMembershipInfo.type))
      );
    })
  );
};

const fetchAccountSummaryEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) => {
  return transformActions(action$, ActionCreators.requestAccountSummary).pipe(
    switchMap((action) => {
      return getAccountSummary(action.payload).pipe(
        map((response) => ActionCreators.receiveAccountSummary(response)),
        catchError((err) => {
          if (err.ErrorCode === 18) {
            const bungie = err.MessageData?.['membershipInfo.membershipType'];
            const membershipType = BUNGIE_REVERSE_MEMBERSHIP_TYPE_MAP[bungie];
            if (membershipType) {
              return observableOf(
                ActionCreators.receiveMembershipInfo({
                  ...action.payload,
                  membershipType,
                })
              );
            }
          }
          return combineErrorActionAndStopLoading('Bungie Error', err.Message);
        }),
        takeUntil(
          action$.ofType(
            ActionCreators.receiveMembershipInfo.type,
            ActionCreators.hidePrivateData.type
          )
        )
      );
    })
  );
};

const fetchMemberships = (action$: ActionsObservable<any>) => {
  return transformActions(action$, ActionCreators.requestMemberships).pipe(
    switchMap((action) => {
      return getMembershipsById(action.payload).pipe(
        map((response) => ActionCreators.receiveMemberships(response)),
        catchError((err) =>
          observableOf(
            showErrorModal({
              errorHeading: 'Bungie Error',
              errorMessage: err.Message,
            })
          )
        ),
        takeUntil(action$.ofType(ActionCreators.receiveMembershipInfo.type))
      );
    })
  );
};

const fetchRaidReportStats = (action$: ActionsObservable<any>) => {
  return transformActions(
    action$,
    ActionCreators.raidReportStatsRequest.started
  ).pipe(
    switchMap((action) => {
      const params = action.payload;
      return getRaidReportStats(params).pipe(
        map((result) =>
          ActionCreators.raidReportStatsRequest.done({ params, result })
        ),
        catchError((err) => {
          const actions: Array<Action<any>> = [
            ActionCreators.raidReportStatsRequest.failed({
              params,
              error: err,
            }),
          ];
          if (err.status !== 404) {
            actions.push(
              showErrorModal({
                errorHeading: 'Dungeon Report Error',
                errorMessage: err.response?.error?.message,
                showReport: true,
              })
            );
          }
          return observableFrom(actions);
        }),
        takeUntil(
          action$.ofType(
            ActionCreators.receiveMembershipInfo.type,
            ActionCreators.hidePrivateData.type
          )
        )
      );
    })
  );
};

const receiveAccountStatsEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) => {
  return transformActions(action$, ActionCreators.receiveAccountStats).pipe(
    mergeMap<any, any>(() => {
      const playerInfo = getCompleteMembershipRequest(state$.value);
      return playerInfo.characterIds.length
        ? observableOf(
            ActionCreators.requestActivities(playerInfo),
            ActionCreators.requestAggregateActivities(playerInfo)
          )
        : combineErrorActionAndStopLoading(
            'Bungie Error',
            'This player has incomplete character data from bungie'
          );
    })
  );
};

const selectCurrentCharactersEpic = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) => {
  return observableZip(
    transformActions(action$, ActionCreators.selectCurrentCharacters),
    transformActions(action$, ActionCreators.receiveAccountSummary)
  ).pipe(
    switchMap(() => {
      const playerInfo = getCompleteMembershipRequestWithFailure(state$.value);
      return observableOf(
        ActionCreators.requestActivities(playerInfo),
        ActionCreators.requestAggregateActivities(playerInfo)
      );
    })
  );
};

const fetchActivitiesEpic = (action$: ActionsObservable<any>) => {
  return transformActions(action$, ActionCreators.requestActivities).pipe(
    switchMap((action) => {
      return observableForkJoin(
        getPlayerActivities(action.payload, 'story'),
        getPlayerActivities(action.payload, 'dungeon')
      ).pipe(
        map(([storyResponse, dungeonResponse]) =>
          storyResponse.concat(dungeonResponse)
        ),
        map((response) => ActionCreators.receiveActivities(response)),
        catchError((err) => {
          // HACK to set fetchActivities = false
          const actions: Array<Action<any>> = [
            ActionCreators.receiveActivities([]),
          ];
          const privacyError = err.ErrorCode === 1665;
          if (privacyError) {
            actions.push(ActionCreators.hidePrivateData());
          }
          return observableMerge(
            observableFrom(actions),
            combineErrorActionAndStopLoading(
              'Bungie Error',
              err.Message,
              privacyError
            )
          );
        }),
        takeUntil(action$.ofType(ActionCreators.receiveMembershipInfo.type))
      );
    })
  );
};

const fetchAggregateStatsEpic = (action$: ActionsObservable<any>) => {
  return transformActions(
    action$,
    ActionCreators.requestAggregateActivities
  ).pipe(
    switchMap((action) => {
      return getPlayerAggregateActivityStats(action.payload).pipe(
        mergeMap((response) =>
          observableOf(
            ActionCreators.receiveAggregateActivities(response),
            ActionCreators.finishLoading()
          )
        ),
        catchError((err) =>
          combineErrorActionAndStopLoading('Bungie Error', err.Message)
        ),
        takeUntil(
          action$.ofType(
            ActionCreators.receiveMembershipInfo.type,
            ActionCreators.hidePrivateData.type
          )
        )
      );
    })
  );
};

const raidReportEpic = combineEpics(
  fetchMembershipInfoEpic,
  receiveMembershipInfoEpic,
  fetchAccountStatsEpic,
  receiveAccountStatsEpic,
  fetchAccountSummaryEpic,
  fetchActivitiesEpic,
  fetchAggregateStatsEpic,
  selectCurrentCharactersEpic,
  fetchMemberships,
  fetchRaidReportStats
);

export default raidReportEpic;
