import { ReactNode } from 'react';
import Footer from './components/Footer';

const AppLayout = ({
  children,
  showFooter = true,
}: {
  children: ReactNode;
  showFooter?: boolean;
}) => {
  return (
    <>
      <main className="drr-container">{children}</main>
      {showFooter && <Footer />}
    </>
  );
};

export default AppLayout;
