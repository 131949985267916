/* eslint-disable no-param-reassign */
import get from 'lodash/get';
import { MATERIAL_ICONS } from '../constants';
import { BasicValue } from '../types/shared';

export const prettyZeroBasicValue = (value: BasicValue) => {
  return value.basic.value ? (
    <p>{value.basic.displayValue}</p>
  ) : (
    <i className="inherit-icon-size error-color material-icons">
      {MATERIAL_ICONS.cancel}
    </i>
  );
};

export const prettyZero = (value, ending = '') => {
  return value ? (
    <p>{value.toLocaleString(navigator.language) + ending}</p>
  ) : (
    <i className="inherit-icon-size error-color material-icons">
      {MATERIAL_ICONS.cancel}
    </i>
  );
};

export const prettyFalse = (item, location) => {
  const value = get(item, location);
  return prettyZero(value);
};

export const prettyEmpty = (array) => prettyFalse(array, 'length');

export const ONE_SEC_IN_MS = 1000;
export const ONE_MIN_IN_SEC = 60;
export const ONE_HOUR_IN_SEC = 60 * ONE_MIN_IN_SEC;
export const ONE_DAY_IN_SEC = 24 * ONE_HOUR_IN_SEC;
export const ONE_WEEK_IN_SEC = 7 * ONE_DAY_IN_SEC;

export const getTimeDisplayValue = (
  seconds: number,
  maxEntries: number = 2
) => {
  seconds = Math.round(seconds);
  let prettyString = '';
  let entries = 0;
  const days = Math.trunc(seconds / ONE_DAY_IN_SEC);
  if (days && maxEntries) {
    entries += 1;
    prettyString += `${days}d `;
    seconds %= ONE_DAY_IN_SEC;
  }
  const hours = Math.trunc(seconds / ONE_HOUR_IN_SEC);
  if ((hours || entries > 0) && entries < maxEntries) {
    entries += 1;
    prettyString += `${hours}h `;
    seconds %= ONE_HOUR_IN_SEC;
  }
  const minutes = Math.trunc(seconds / ONE_MIN_IN_SEC);
  if ((minutes || entries > 0) && entries < maxEntries) {
    entries += 1;
    prettyString += `${minutes}m `;
    seconds %= ONE_MIN_IN_SEC;
  }
  if (entries < maxEntries) {
    prettyString += `${seconds}s`;
  }
  return prettyString.trim();
};

export const prettyPrintTime = (seconds, maxEntries) => {
  seconds = Math.round(seconds);
  // eslint-disable-next-line no-restricted-globals
  if (!seconds || !isFinite(seconds)) {
    return prettyZero(0);
  }
  return getTimeDisplayValue(seconds, maxEntries);
};

export const prettyPrintTimeExtended = (seconds, maxEntries) => {
  let prettyString = '';
  let entries = 0;
  const weeks = Math.trunc(seconds / ONE_WEEK_IN_SEC);
  if (weeks) {
    entries += 1;
    prettyString += `${weeks}w `;
    seconds %= ONE_WEEK_IN_SEC;
  }
  return (prettyString + prettyPrintTime(seconds, maxEntries - entries)).trim();
};

export const appendRank = (base: string, rankDetails?: { rank?: number }) => {
  const ending = rankDetails && rankDetails.rank ? ` #${rankDetails.rank}` : '';
  return base + ending;
};
