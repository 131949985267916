import {
  Component,
  ComponentType,
  KeyboardEvent,
  KeyboardEventHandler,
} from 'react';
import { ActionCreator } from 'typescript-fsa';
import { MembershipInfo } from '../types/membership-info';

interface Props {
  dispatch: any;
}

export interface ChildSearchProps {
  handleKeyUp: KeyboardEventHandler<HTMLInputElement>;
  handleClick: () => void;
  refHandlers: any;
}

export default abstract class SearchContainer extends Component<Props, {}> {
  private inputElement!: HTMLInputElement;

  private refHandlers = {
    search: (ref) => {
      this.inputElement = ref;
    },
  };

  private dispatchMembershipRequest = () => {
    const displayName = this.getDisplayName();
    const { dispatch } = this.props;
    if (displayName) {
      dispatch(this.requestMembershipInfo({ displayName }));
    }
  };

  private getDisplayName = () => {
    return this.inputElement.value.trimEnd();
  };

  private handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      this.inputElement.blur();
      this.dispatchMembershipRequest();
    }
  };

  protected abstract Child: ComponentType<ChildSearchProps>;

  protected abstract requestMembershipInfo: ActionCreator<
    Partial<MembershipInfo>
  >;

  public render() {
    return (
      <this.Child
        refHandlers={this.refHandlers}
        handleKeyUp={this.handleKeyUp}
        handleClick={this.dispatchMembershipRequest}
      />
    );
  }
}
