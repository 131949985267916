import { useMediaQuery } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { ReactNode, useMemo } from 'react';
import { connect } from 'react-redux';
import { RootState } from './store';
import { getTheme } from './store/settings/selectors';

const mapStateToProps = (state: RootState) => ({
  theme: getTheme(state),
});

type Props = ReturnType<typeof mapStateToProps> & { children: ReactNode };

const ThemeContainer = ({ theme, children }: Props) => {
  const darkMode = useMediaQuery('(prefers-color-scheme: dark)', {
    noSsr: true,
  });

  const type = useMemo(() => {
    if (theme !== 'device') {
      return theme;
    }
    return darkMode ? 'dark' : 'light';
  }, [darkMode, theme]);

  const muiTheme = useMemo(() => {
    return createTheme({
      palette: {
        background: {
          default: type === 'dark' ? '#333333' : '#fafafa',
          paper: type === 'dark' ? '#252526' : '#fff',
        },
        primary: { main: '#03a9f4' },
        type,
      },
      props: {
        MuiTooltip: {
          enterTouchDelay: 0,
        },
        MuiUseMediaQuery: {
          noSsr: true,
        },
      },
    });
  }, [type]);
  return <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>;
};

export default connect(mapStateToProps)(ThemeContainer);
