import { RANK_TIER } from '../types/rank';
import { Subscription, SubscriptionTier } from '../types/subscription';
import { RANK_TIERS } from './rank';

const GOLD_SUBSCRIPTION: Subscription = {
  tier: SubscriptionTier.GOLD,
  features: {
    removesAds: true,
    addsProfileBadge: true,
  },
  displayProperties: {
    title: 'Gold',
    color: RANK_TIERS[RANK_TIER.GOLD].backgroundColor,
  },
  prices: [
    { amount: 4.99, interval: 'month' },
    { amount: 49.99, interval: 'year' },
  ],
};

const DIAMOND_SUBSCRIPTION: Subscription = {
  tier: SubscriptionTier.DIAMOND,
  features: {
    removesAds: true,
    addsProfileBadge: true,
    addsLeaderboardFlair: true,
  },
  displayProperties: {
    title: 'Diamond',
    color: RANK_TIERS[RANK_TIER.DIAMOND].backgroundColor,
  },
  prices: [
    { amount: 19.99, interval: 'month' },
    { amount: 199.99, interval: 'year' },
  ],
};

export const SUBSCRIPTIONS = [GOLD_SUBSCRIPTION, DIAMOND_SUBSCRIPTION];

export const SUBSCRIPTION_MAP = SUBSCRIPTIONS.reduce(
  (prev, curr) => ({ ...prev, [curr.tier]: curr }),
  {} as { [tier in SubscriptionTier]: Subscription | undefined }
);
