import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { MembershipInfo } from '../../types/membership-info';
import { Milestones } from '../../types/milestones';

export interface SharedState {
  fetchingMilestones: boolean;
  milestones: Milestones;
  swipeIndexes: {
    [key: string]: number;
  };
}

const initialState: SharedState = {
  fetchingMilestones: false,
  milestones: {},
  swipeIndexes: {},
};

enum Action {
  REQUEST_MEMBERSHIP_INFO,
  REQUEST_MILESTONES,
  RECEIVE_MILESTONES,
  FAILURE_MILESTONES,
  SWIPE,
  WORLDS_FIRST_REQUEST,
}

interface MembershipInfoRequest extends Partial<MembershipInfo> {
  receiveMembershipInfoType: string;
  completeMembershipInfoType: string;
}

const actionCreator = actionCreatorFactory('SHARED');
export const requestMembershipInfo = actionCreator<MembershipInfoRequest>(
  Action[Action.REQUEST_MEMBERSHIP_INFO]
);
export const requestMilestones = actionCreator(
  Action[Action.REQUEST_MILESTONES]
);
export const receiveMilestones = actionCreator<Milestones>(
  Action[Action.RECEIVE_MILESTONES]
);
export const failureMilestones = actionCreator(
  Action[Action.FAILURE_MILESTONES]
);

export const swipe = actionCreator<{ key: string; index: number }>(
  Action[Action.SWIPE]
);

export const ActionCreators = {
  requestMembershipInfo,
  requestMilestones,
  receiveMilestones,
  failureMilestones,
};

export default reducerWithInitialState(initialState)
  .case(requestMilestones, (state, payload) => {
    return {
      ...state,
      milestones: initialState.milestones,
      fetchingMilestones: true,
    };
  })
  .case(receiveMilestones, (state, milestones) => {
    return { ...state, milestones, fetchingMilestones: false };
  })
  .case(failureMilestones, (state, payload) => {
    return {
      ...state,
      milestones: initialState.milestones,
      fetchingMilestones: false,
    };
  })
  .case(swipe, (state, payload) => {
    return {
      ...state,
      swipeIndexes: {
        ...state.swipeIndexes,
        [payload.key]: payload.index,
      },
    };
  });
