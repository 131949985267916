import { SITE_CARD_BACKGROUND_IMAGE, SITE_NAME } from '.';
import AllCard from '../containers/HomeContainer/components/AllCard';
import { DisplayRaid, HomePageRaid, RAID, Raid } from '../types/raid-report';
import {
  createContestBadgeConfiguration,
  createContestDayOneBadgeConfiguration,
  createWeekOneBadgeConfiguration,
  DAY_ONE_BADGE_CONFIGURATION,
  DEFAULT_BEST_PLAYER_COUNT_CONFIGURATION,
} from './badge-configuration';

export const CLASS_HASH_MAP = {
  3655393761: 'Titan',
  671679327: 'Hunter',
  2271682572: 'Warlock',
};

export const FIRST_LAUNCH_WEEK = '2018-07-17T17:00:00.000Z';
export const BEYOND_LIGHT_LAUNCH = '2020-11-10T17:00:00.000Z';
export const WITCH_QUEEN_LAUNCH = '2022-02-22T17:00:00.000Z';

export const WHISPER: Raid = {
  raid: RAID.WHISPER,
  displayValue: 'The Whisper',
  boss: 'the bosses',
  launchTime: '2018-07-20T17:00:00.000Z',
  versions: [
    {
      activityHashes: [1099555105],
      displayValue: 'Heroic',
    },
    {
      activityHashes: [74501540],
      displayValue: 'Normal',
    },
  ],
  worldFirstBadgeConfiguration: [
    DAY_ONE_BADGE_CONFIGURATION,
    createWeekOneBadgeConfiguration(),
  ],
  bestPlayerCountBadgeConfiguration: DEFAULT_BEST_PLAYER_COUNT_CONFIGURATION,
  deprecated: true,
};

export const SHATTERED_THRONE: Raid = {
  raid: RAID.SHATTERED_THRONE,
  displayValue: 'Shattered Throne',
  boss: 'Dûl Incaru',
  launchTime: '2018-09-25T17:00:00.000Z',
  versions: [
    {
      activityHashes: [2032534090],
      displayValue: 'Normal',
    },
  ],
  worldFirstBadgeConfiguration: [
    DAY_ONE_BADGE_CONFIGURATION,
    createWeekOneBadgeConfiguration(),
  ],
  bestPlayerCountBadgeConfiguration: DEFAULT_BEST_PLAYER_COUNT_CONFIGURATION,
  // Curse This
  flawlessRecordHash: 1178448425,
  // Solo-nely
  soloRecordHash: 3899996566,
  // Seriously, Never Again
  soloFlawlessRecordHash: 3205009787,
};

export const ZERO_HOUR: Raid = {
  raid: RAID.ZERO_HOUR,
  displayValue: 'Zero Hour',
  boss: 'Siriks',
  launchTime: '2019-05-07T17:00:00.000Z',
  versions: [
    {
      activityHashes: [2731208666],
      displayValue: 'Heroic',
    },
    {
      activityHashes: [3232506937],
      displayValue: 'Normal',
    },
  ],
  worldFirstBadgeConfiguration: [
    DAY_ONE_BADGE_CONFIGURATION,
    createWeekOneBadgeConfiguration(),
  ],
  bestPlayerCountBadgeConfiguration: DEFAULT_BEST_PLAYER_COUNT_CONFIGURATION,
  deprecated: true,
};

export const PIT_OF_HERESY: Raid = {
  raid: RAID.PIT_OF_HERESY,
  displayValue: 'Pit of Heresy',
  boss: 'Zulmak',
  launchTime: '2019-10-29T17:00:00.000Z',
  versions: [
    {
      activityHashes: [1375089621, 2582501063],
      displayValue: 'Normal',
    },
  ],
  worldFirstBadgeConfiguration: [
    DAY_ONE_BADGE_CONFIGURATION,
    createWeekOneBadgeConfiguration(),
  ],
  bestPlayerCountBadgeConfiguration: DEFAULT_BEST_PLAYER_COUNT_CONFIGURATION,
  // Eternal Heretic
  flawlessRecordHash: 245952203,
  // Usurper
  soloRecordHash: 3841336511,
  // Savior of the Deep
  soloFlawlessRecordHash: 3950599483,
};

export const PROPHECY: Raid = {
  raid: RAID.PROPHECY,
  displayValue: 'Prophecy',
  boss: 'Kell Echo',
  launchTime: '2020-06-10T00:00:00.000Z',
  versions: [
    {
      activityHashes: [4148187374, 1077850348],
      displayValue: 'Normal',
    },
  ],
  worldFirstBadgeConfiguration: [
    DAY_ONE_BADGE_CONFIGURATION,
    // Launched 7 hours after Tuesday reset
    createWeekOneBadgeConfiguration(7 * 24 - 7),
  ],
  bestPlayerCountBadgeConfiguration: DEFAULT_BEST_PLAYER_COUNT_CONFIGURATION,
  // Prophecy Dungeon Flawless
  flawlessRecordHash: 2010041484,
  // Prophecy Dungeon Solo
  soloRecordHash: 3002642730,
  // Prophecy Dungeon Solo Flawless
  soloFlawlessRecordHash: 3191784400,
  invalidStartingPhaseIndexes: [10, 11],
};

export const HARBINGER: Raid = {
  raid: RAID.HARBINGER,
  displayValue: 'Harbinger',
  boss: 'Akorith',
  launchTime: '2021-01-19T17:00:00.000Z',
  versions: [
    {
      activityHashes: [1738383283],
      displayValue: 'Normal',
    },
  ],
  worldFirstBadgeConfiguration: [
    DAY_ONE_BADGE_CONFIGURATION,
    createWeekOneBadgeConfiguration(7 * 24),
  ],
  bestPlayerCountBadgeConfiguration: DEFAULT_BEST_PLAYER_COUNT_CONFIGURATION,
  // Immortal Harbinger
  flawlessRecordHash: 2902814383,
  // Lone Harbinger
  soloRecordHash: 3657275647,
  // Alpha Hunter
  soloFlawlessRecordHash: 3047181179,
  deprecated: true,
};

export const PRESAGE: Raid = {
  raid: RAID.PRESAGE,
  displayValue: 'Presage',
  boss: 'Locus of Communion',
  launchTime: '2021-02-16T17:00:00.000Z',
  versions: [
    {
      activityHashes: [4212753278],
      displayValue: 'Master',
    },
    {
      activityHashes: [2124066889],
      displayValue: 'Normal',
    },
  ],
  worldFirstBadgeConfiguration: [
    DAY_ONE_BADGE_CONFIGURATION,
    createWeekOneBadgeConfiguration(7 * 24),
  ],
  bestPlayerCountBadgeConfiguration: DEFAULT_BEST_PLAYER_COUNT_CONFIGURATION,
  // Tale Told - master only
  flawlessRecordHash: 2335417976,
  // No solo record
  // Lone Gun in a Dark Place
  soloFlawlessRecordHash: 4206923617,
  deprecated: true,
};

export const GRASP_OF_AVARICE: Raid = {
  raid: RAID.GRASP_OF_AVARICE,
  displayValue: 'Grasp of Avarice',
  boss: 'Captain Avarokk',
  launchTime: '2021-12-07T17:00:00.000Z',
  versions: [
    {
      activityHashes: [3774021532, 1112917203],
      displayValue: 'Master',
    },
    {
      activityHashes: [4078656646],
      displayValue: 'Normal',
    },
  ],
  worldFirstBadgeConfiguration: [
    DAY_ONE_BADGE_CONFIGURATION,
    createWeekOneBadgeConfiguration(7 * 24),
  ],
  bestPlayerCountBadgeConfiguration: DEFAULT_BEST_PLAYER_COUNT_CONFIGURATION,
  // Grasp of Avarice Flawless
  flawlessRecordHash: 2693589427,
  // Grasp of Avarice Solo
  soloRecordHash: 678858776,
  // Grasp of Avarice Solo Flawless
  soloFlawlessRecordHash: 3718971745,
};

export const DUALITY: Raid = {
  raid: RAID.DUALITY,
  displayValue: 'Duality',
  boss: 'Nightmare of Caiatl',
  launchTime: '2022-05-27T17:00:00.000Z',
  versions: [
    {
      activityHashes: [1668217731, 3012587626],
      displayValue: 'Master',
    },
    {
      activityHashes: [2823159265],
      displayValue: 'Normal',
    },
  ],
  worldFirstBadgeConfiguration: [
    DAY_ONE_BADGE_CONFIGURATION,
    createWeekOneBadgeConfiguration(4 * 24),
  ],
  bestPlayerCountBadgeConfiguration: DEFAULT_BEST_PLAYER_COUNT_CONFIGURATION,
  // Flawless Thoughtstealer
  flawlessRecordHash: 1506696941,
  // Solo Thoughtstealer
  soloRecordHash: 755549938,
  // Perfected Thoughtstealer
  soloFlawlessRecordHash: 4126703847,
};

export const SPIRE_OF_THE_WATCHER: Raid = {
  raid: RAID.SPIRE_OF_THE_WATCHER,
  displayValue: 'Spire of the Watcher',
  boss: 'Persys',
  launchTime: '2022-12-09T17:00:00.000Z',
  versions: [
    {
      activityHashes: [1801496203, 2296818662],
      displayValue: 'Master',
    },
    {
      activityHashes: [1262462921],
      displayValue: 'Normal',
    },
  ],
  worldFirstBadgeConfiguration: [
    DAY_ONE_BADGE_CONFIGURATION,
    createWeekOneBadgeConfiguration(4 * 24),
  ],
  bestPlayerCountBadgeConfiguration: DEFAULT_BEST_PLAYER_COUNT_CONFIGURATION,
  // Mind the Vexplosion
  flawlessRecordHash: 1591054373,
  // The Magnificent One
  soloRecordHash: 1151761978,
  // Untarnished Grit
  soloFlawlessRecordHash: 4032136335,
};

export const GHOSTS_OF_THE_DEEP: Raid = {
  raid: RAID.GHOSTS_OF_THE_DEEP,
  displayValue: 'Ghosts of the Deep',
  boss: 'Šimmumah ur-Nokru',
  launchTime: '2023-05-26T17:00:00.000Z',
  versions: [
    {
      activityHashes: [2716998124],
      displayValue: 'Master',
    },
    {
      activityHashes: [313828469],
      displayValue: 'Normal',
    },
  ],
  worldFirstBadgeConfiguration: [
    DAY_ONE_BADGE_CONFIGURATION,
    createWeekOneBadgeConfiguration(4 * 24),
  ],
  bestPlayerCountBadgeConfiguration: DEFAULT_BEST_PLAYER_COUNT_CONFIGURATION,
  // Deny Death
  flawlessRecordHash: 4050558210,
  // Alone in the Deep
  soloRecordHash: 3584441401,
  // King of the Deep
  soloFlawlessRecordHash: 2105117002,
};

export const WARLORDS_RUIN: Raid = {
  raid: RAID.WARLORDS_RUIN,
  displayValue: `Warlord's Ruin`,
  boss: `Hefnd's Vengeance`,
  launchTime: '2023-12-01T17:00:00.000Z',
  versions: [
    {
      activityHashes: [2534833093],
      displayValue: 'Master',
    },
    {
      activityHashes: [2004855007],
      displayValue: 'Normal',
    },
  ],
  worldFirstBadgeConfiguration: [
    DAY_ONE_BADGE_CONFIGURATION,
    createWeekOneBadgeConfiguration(4 * 24),
  ],
  bestPlayerCountBadgeConfiguration: DEFAULT_BEST_PLAYER_COUNT_CONFIGURATION,
  // Their Blood, Not Yours, O Vengeance Mine
  flawlessRecordHash: 982579018,
  // She Stood Alone, O Vengeance Mine
  soloRecordHash: 2905044529,
  // Become Naeem's Avatar, O Vengeance Mine
  soloFlawlessRecordHash: 238037026,
};

export const VESPERS_HOST: Raid = {
  raid: RAID.VESPERS_HOST,
  displayValue: `Vesper's Host`,
  boss: `the Corrupted Puppeteer`,
  launchTime: '2024-10-11T17:00:00.000Z',
  versions: [
    {
      activityHashes: [4293676253],
      displayValue: 'Master',
    },
    {
      activityHashes: [3492566689, 1915770060, 300092127],
      displayValue: 'Normal',
    },
  ],
  worldFirstBadgeConfiguration: [
    createContestDayOneBadgeConfiguration(1915770060),
    // Orbital Tournament
    { ...createContestBadgeConfiguration(1915770060), recordHash: 1136171205 },
    DAY_ONE_BADGE_CONFIGURATION,
    createWeekOneBadgeConfiguration(4 * 24),
  ],
  bestPlayerCountBadgeConfiguration: DEFAULT_BEST_PLAYER_COUNT_CONFIGURATION,
  // Flawless Networking
  flawlessRecordHash: 1141690897,
  // Solo Spelunker
  soloRecordHash: 4209518862,
  // Perfect Solitude
  soloFlawlessRecordHash: 1553599507,
};

export const INDIVIDUAL_RAIDS = [
  VESPERS_HOST,
  WARLORDS_RUIN,
  GHOSTS_OF_THE_DEEP,
  SPIRE_OF_THE_WATCHER,
  DUALITY,
  GRASP_OF_AVARICE,
  PROPHECY,
  PIT_OF_HERESY,
  SHATTERED_THRONE,
  PRESAGE,
  HARBINGER,
  ZERO_HOUR,
  WHISPER,
];

export const FALL_BACK_ACTIVITY_INFO: {
  displayRaid: DisplayRaid;
  displayVersion: string;
} = {
  displayRaid: {
    displayValue: SITE_NAME,
    image: SITE_CARD_BACKGROUND_IMAGE,
  },
  displayVersion: 'Non-Dungeon',
};

export const ALL_RAID: HomePageRaid = {
  raid: RAID.ALL,
  displayValue: 'All Dungeons',
  image: 'https://img.raidreport.dev/home.jpg',
  homePageCard: AllCard,
};

export const ALL_RAIDS = [ALL_RAID, ...INDIVIDUAL_RAIDS];
