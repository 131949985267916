import { RankInformation, RANK_TIER } from '../types/rank';

export const RANK_TIERS: { [tier: string]: RankInformation } = {
  [RANK_TIER.UNRANKED]: { backgroundColor: 'grey', tooltip: '' },
  [RANK_TIER.BRONZE]: { backgroundColor: '#6a5b3f', tooltip: '100% of' },
  [RANK_TIER.SILVER]: { backgroundColor: '#9ea3b0', tooltip: '70% of' },
  [RANK_TIER.GOLD]: { backgroundColor: '#fabc44', tooltip: '30% of' },
  [RANK_TIER.PLATINUM]: { backgroundColor: '#04b1a1', tooltip: '10% of' },
  [RANK_TIER.DIAMOND]: { backgroundColor: '#048ab4', tooltip: '2% of' },
  [RANK_TIER.MASTER]: { backgroundColor: '#fa576f', tooltip: '1000' },
  [RANK_TIER.CHALLENGER]: { backgroundColor: '#fa576f', tooltip: '200' },
};
