const apiKey = '2f4b6880fd6e47c89085cd8c9cd6c127';

export { BUNGIE_URL } from '../../constants';
export const BUNGIE_PGCR_URL = 'https://stats.bungie.net';
export const DESTINY_PATH = 'Platform/Destiny2';
export const USER_PATH = 'Platform/User';
export const BUNGIE_HEADERS = {
  headers: {
    'X-API-Key': apiKey,
  },
};
