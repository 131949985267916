import {
  MembershipInfo,
  MembershipsResponse,
} from '../../types/membership-info';
import { UserSearchResponse } from '../../types/search';
import { createUserRequest } from './util';

export const getMembershipsById = (player: MembershipInfo) => {
  const path = [
    'GetMembershipsById',
    player.membershipId,
    player.membershipType,
  ];
  return createUserRequest<MembershipsResponse>(path);
};

export const getMembershipFromHardLinkedCredential = (
  credentialType: number,
  credential: string
) => {
  const path = [
    'GetMembershipFromHardLinkedCredential',
    credentialType,
    credential,
  ];
  return createUserRequest<{
    membershipId: string;
    membershipType: number;
  }>(path);
};

export const searchForBungieUser = (displayName: string, page = 0) => {
  const encodedDisplayName = encodeURIComponent(displayName);
  const path = ['Search', 'Prefix', encodedDisplayName, page];
  return createUserRequest<UserSearchResponse>(path);
};

export const getMembershipFromSteamId = (steamId: string) => {
  return getMembershipFromHardLinkedCredential(12, steamId);
};
