export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const waitForRedirect = async (url?: string) => {
  if (!url) {
    throw new Error('No redirect url provided');
  }
  window.location.href = url;
  await sleep(10000);
  throw new Error(`Failed to redirect to ${url}`);
};
