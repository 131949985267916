import { LocationChangeAction } from 'connected-react-router';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { interval as observableInterval, of as observableOf } from 'rxjs';
import { catchError, map, switchMap, tap, throttle } from 'rxjs/operators';
import { getRaidReportNotifications } from '../../services/notification';
import { updateServiceWorker } from '../../serviceWorker';
import { ONE_MIN_IN_SEC, ONE_SEC_IN_MS } from '../../utils/ui-helper';
import { transformActions } from '../util';
import { ActionCreators } from './reducer';

const notificationInterval = 15 * ONE_MIN_IN_SEC * ONE_SEC_IN_MS;

const routerChangeEpic = (action$: ActionsObservable<any>) => {
  return action$.ofType('@@router/LOCATION_CHANGE').pipe(
    tap((action: LocationChangeAction) => {
      if (action.payload.action === 'PUSH' && !action.payload.location.search) {
        window.scrollTo(0, 0);
      }
      updateServiceWorker();
    }),
    throttle(() => observableInterval(notificationInterval)),
    switchMap(() => {
      return observableOf(ActionCreators.notificationRequest.started());
    })
  );
};

const fetchNotificationsEpic = (action$: ActionsObservable<any>) => {
  return transformActions(
    action$,
    ActionCreators.notificationRequest.started
  ).pipe(
    switchMap(() => {
      return getRaidReportNotifications().pipe(
        map((result) => {
          return ActionCreators.notificationRequest.done({
            result,
            params: undefined,
          });
        }),
        catchError((error) => {
          return observableOf(
            ActionCreators.notificationRequest.failed({
              error,
              params: undefined,
            })
          );
        })
      );
    })
  );
};

const notificationEpic = combineEpics(routerChangeEpic, fetchNotificationsEpic);

export default notificationEpic;
