import moment from 'moment';
import { createSelector } from 'reselect';
import { RootState } from '..';
import { Notification } from '../../types/notification';

export const getNotificationState = (state: RootState) => state.notification;

export const getNotifications = (state: RootState) =>
  getNotificationState(state).notifications;
export const getDismissedNotificationIds = (state: RootState) =>
  getNotificationState(state).dismissedNotificationIds;
export const getHiddenNotificationIds = (state: RootState) =>
  getNotificationState(state).hiddenNotificationIds;

export const getActiveNotifications = createSelector(
  getNotifications,
  getDismissedNotificationIds,
  getHiddenNotificationIds,
  (notifications, dismissedNotificationIds, hiddenNotificationIds) => {
    return notifications.filter((notification) => {
      return (
        dismissedNotificationIds.indexOf(notification.id) === -1 &&
        hiddenNotificationIds.indexOf(notification.id) === -1 &&
        (!notification.expires || moment().isBefore(notification.expires))
      );
    });
  }
);

export const getActiveNotification = createSelector(
  getActiveNotifications,
  (notifications): Notification | undefined => {
    return notifications[0];
  }
);
