import { Button, Snackbar } from '@material-ui/core';
import { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { useRehydrated } from '../../store/db';
import {
  dismissNotification,
  hideNotification,
} from '../../store/notification/reducer';
import { getActiveNotification } from '../../store/notification/selectors';

const notificationTranslations = {
  dismiss: 'Dismiss',
};

const mapStateToProps = (state: RootState) => ({
  activeNotification: getActiveNotification(state),
});

const dispatchToProps = {
  dismissNotification,
  hideNotification,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchToProps;

const initialState = { open: true };

const NotificationContainer = (props: Props) => {
  const { activeNotification } = props;
  const [state, setState] =
    useState<{ open: boolean; dismiss?: boolean }>(initialState);
  const rehydrated = useRehydrated();

  if (!activeNotification || !rehydrated) {
    return null;
  }

  const handleDismissClick = () => {
    setState({ open: false, dismiss: true });
  };
  const handleClose = (event, reason: string) => {
    if (reason === 'clickaway' && !activeNotification.hideOnClickaway) {
      return;
    }
    setState({ open: false, dismiss: activeNotification.dismissWithoutClick });
  };
  const handleExited = () => {
    if (state.dismiss) {
      props.dismissNotification(activeNotification.id);
    } else {
      props.hideNotification(activeNotification.id);
    }
    setState(initialState);
  };

  return (
    <Snackbar
      key={activeNotification.id}
      open={state.open}
      autoHideDuration={activeNotification.timeout}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      onClose={handleClose}
      onExited={handleExited}
      ContentProps={{
        'aria-describedby': 'notification-message',
      }}
      message={
        <div
          id="notification-message"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: activeNotification.message }}
        />
      }
      action={
        <Button onClick={handleDismissClick} color="primary">
          {notificationTranslations.dismiss}
        </Button>
      }
    />
  );
};

export default connect(mapStateToProps, dispatchToProps)(NotificationContainer);
