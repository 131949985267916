import { PaletteType } from '@material-ui/core';
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

export interface SettingsState {
  theme: PaletteType | 'device';
  mobileLayout?: 'vertical' | 'horizontal';
  filter?: 'default' | 'success' | 'all' | 'star';
}

const initialState: SettingsState = {
  theme: 'device',
};

enum Action {
  SAVE_SETTINGS,
}

const actionCreator = actionCreatorFactory('Settings');
export const saveSettings = actionCreator<SettingsState>(
  Action[Action.SAVE_SETTINGS]
);

export const ActionCreators = {
  saveSettings,
};

export default reducerWithInitialState(initialState).case(
  saveSettings,
  (state, settings) => {
    return settings;
  }
);
