import * as Sentry from '@sentry/browser';
import { routerMiddleware, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import { persistReducer, PersistState, persistStore } from 'redux-persist';
import createSentryMiddleware from 'redux-sentry-middleware';
import { persistConfig } from './db';
import rootEpic from './epics';
import createRootReducer, { DRRState } from './reducers';
import { setupSentry } from './sentry';

export interface RootState extends DRRState {
  router: RouterState;
  _persist?: PersistState;
}

const release = process.env.REACT_APP_VERSION!;
const environment = process.env.REACT_APP_ENV;

setupSentry(release, environment!);

export const history = createBrowserHistory();

const epicMiddleware = createEpicMiddleware();
const sentryMiddleware = createSentryMiddleware(Sentry, {
  stateTransformer: (state: DRRState) => state.raidReport.membershipInfo,
  actionTransformer: (action) => ({ type: action.type }),
});

const middleware = [
  routerMiddleware(history),
  epicMiddleware,
  sentryMiddleware,
];

const rootReducer = createRootReducer(history);

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
export const persistor = persistStore(store);

epicMiddleware.run(rootEpic);
