import './DestinyCard.scss';

const DestinyCard = (props: any) => {
  const { children, emblemImage, ...styleProps } = props;
  return (
    <div
      className="card destiny-card"
      style={{ ...styleProps, backgroundSize: 'cover' }}
    >
      {emblemImage && (
        <img className="destiny-card-emblem" src={emblemImage} alt="" />
      )}
      <div className="destiny-card-content">{children}</div>
    </div>
  );
};

export default DestinyCard;
