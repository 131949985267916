import { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { ModalType } from '../../store/modal/reducer';
import { getModal } from '../../store/modal/selectors';
import ErrorModal from './components/ErrorModal';
import SelectorModal from './components/SelectorModal';
import UpsellModal from './components/UpsellModal';

const mapStateToProps = (state: RootState) => ({
  ...getModal(state),
});

type Props = ReturnType<typeof mapStateToProps>;

class ModalContainer extends Component<Props, {}> {
  private MODAL_COMPONENTS = {
    [ModalType.SELECT_MEMBERSHIP_TYPE]: SelectorModal,
    [ModalType.ERROR_TYPE]: ErrorModal,
    [ModalType.UPSELL_TYPE]: UpsellModal,
  };

  public render() {
    const { modalType, modalProps, showModal } = this.props;
    if (!modalType || !this.MODAL_COMPONENTS[modalType]) {
      return null;
    }
    const SpecificModal = this.MODAL_COMPONENTS[modalType];
    return <SpecificModal {...modalProps} showModal={showModal} />;
  }
}

export default connect(mapStateToProps)(ModalContainer);
