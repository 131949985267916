import { useEffect, useRef } from 'react';

// Based on https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (
  callback: () => void,
  delay: number | undefined
) => {
  const savedCallback = useRef(callback);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (typeof delay !== 'number') return undefined;

    const id = setInterval(() => savedCallback.current(), delay);
    return () => clearInterval(id);
  }, [delay]);
};
