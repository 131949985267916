import {
  Observable,
  of as observableOf,
  throwError as observableThrowError,
} from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { RaidReportResponse } from '../../types/raid-report';
import { createRequest } from '../util';
import { RAID_REPORT_URL, SEARCH_ENDPOINT } from './constants';

const createAndResolveRaidReportRequest = <T>(
  path: Array<string | number>,
  qs: any = undefined,
  baseUrl: string
): Observable<T> => {
  path.unshift(baseUrl);
  return createRequest<RaidReportResponse<T>>(path, qs).pipe(
    mergeMap((body) =>
      body.error ? observableThrowError(body.error) : observableOf(body)
    ),
    map((body) => body.response)
  );
};

export const createRaidReportRequest = <T>(
  path: Array<string | number>,
  qs: any = undefined
): Observable<T> => {
  return createAndResolveRaidReportRequest(path, qs, RAID_REPORT_URL);
};

export const createRaidReportSearchRequest = <T>(
  path: Array<string | number>,
  qs: any = undefined
): Observable<T> => {
  return createAndResolveRaidReportRequest(path, qs, SEARCH_ENDPOINT);
};
