import {
  alpha,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import LoadingBar from '../../../components/LoadingBar';
import {
  hideModal,
  ModalShowState,
  UpsellProps,
} from '../../../store/modal/reducer';
import Features from '../../AccountContainer/components/PriceCard/Features';
import { useStripeCheckout } from '../../AccountContainer/useStripeCheckout';

const useStyles = makeStyles(() => ({
  loadingContainer: {
    minHeight: 4,
    marginTop: '0.5rem',
    marginBottom: '1rem',
  },
  title: createStyles(({ color }: { color: string }) => ({
    borderTop: '4px solid',
    borderTopColor: color,
    backgroundColor: alpha(color, 0.1),
  })),
}));

type Props = ModalShowState & UpsellProps;

const UpsellModal = ({ showModal, subscription }: Props) => {
  const { displayProperties, prices, tier } = subscription;
  const classes = useStyles({ color: displayProperties.color });
  const dispatch = useDispatch();
  const { mutate, isError, error, isLoading } = useStripeCheckout();

  return (
    <Dialog
      open={showModal}
      onClose={() => dispatch(hideModal())}
      aria-labelledby="upsell-dialog-title"
    >
      <DialogTitle id="upsell-dialog-title" className={classes.title}>
        Upgrade to {displayProperties.title}
      </DialogTitle>
      <DialogContent>
        <Features subscription={subscription} />
        <div className={classes.loadingContainer}>
          {isError && (
            <Alert severity="error">
              {(error as any)?.response?.error?.message ||
                (error as Error)?.message ||
                'An error occurred'}
            </Alert>
          )}
          {isLoading && <LoadingBar />}
        </div>
        <Typography color="textSecondary" variant="body2">
          Existing subscriptions need to be canceled separately
        </Typography>
      </DialogContent>
      <DialogActions>
        {prices.map((p) => {
          return (
            <Button
              key={p.interval}
              onClick={() => {
                mutate({ tier, interval: p.interval });
              }}
              color="primary"
              disabled={isLoading}
            >
              ${p.amount}/{p.interval === 'year' ? 'yr' : 'mo'}
            </Button>
          );
        })}
      </DialogActions>
    </Dialog>
  );
};

export default UpsellModal;
