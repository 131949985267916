import { init } from '@sentry/browser';

export const setupSentry = (release: string, environment: string) => {
  init({
    dsn: 'https://4970c00186f74b69b12a12c2bc5f6234@o180187.ingest.sentry.io/5270868',
    release,
    environment,
    whitelistUrls: [
      'dungeon.report/static/js',
      'dungeon-report.netlify.app/static/js',
      'localhost:3000/static/js',
    ],
    ignoreErrors: [
      // Edge issue with html of ads
      `Can't execute code from a freed script`,
      // Edge issues with ads + others that will be lost
      'Object expected',
      `Unable to get property 'getBoundingClientRect' of undefined or null reference`,
      // Issue with new releases on Netlify
      `Unexpected token '<'`,
      `expected expression, got '<'`,
      // Service worker issues that use up too much of the quota
      'service-worker.js load failed',
      'newestWorker is null',
      'service-worker.js for scope',
      'ServiceWorker for scope',
    ],
  });
};
