import { createTransform } from 'redux-persist';
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers/dist';
import { Notification } from '../../types/notification';

export interface NotificationState {
  notifications: Notification[];
  dismissedNotificationIds: string[];
  hiddenNotificationIds: string[];
  fetchingNotifications?: boolean;
}

const initialState: NotificationState = {
  notifications: [],
  dismissedNotificationIds: [],
  hiddenNotificationIds: [],
};

export const notificationTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState: Partial<NotificationState>) => {
    return { dismissedNotificationIds: inboundState.dismissedNotificationIds };
  },
  // transform state being rehydrated
  (outboundState) => {
    return outboundState;
  },
  // define which reducers this transform gets called for.
  { whitelist: ['notification'] }
);

enum Action {
  DISMISS_NOTIFICATION,
  HIDE_NOTIFICATION,
  NOTIFICATION_REQUEST,
}

const actionCreator = actionCreatorFactory('NOTIFICATION');

export const dismissNotification = actionCreator<string>(
  Action[Action.DISMISS_NOTIFICATION]
);
export const hideNotification = actionCreator<string>(
  Action[Action.HIDE_NOTIFICATION]
);

export const notificationRequest = actionCreator.async<void, Notification[]>(
  Action[Action.NOTIFICATION_REQUEST]
);

export const ActionCreators = {
  dismissNotification,
  notificationRequest,
};

export default reducerWithInitialState(initialState)
  .case(notificationRequest.started, (state, payload) => {
    return {
      ...state,
      fetchingNotifications: true,
    };
  })
  .case(notificationRequest.done, (state, payload) => {
    return {
      ...state,
      notifications: payload.result,
      fetchingNotifications: false,
    };
  })
  .case(notificationRequest.failed, (state, payload) => {
    return {
      ...state,
      fetchingNotifications: false,
    };
  })
  .case(dismissNotification, (state, payload) => {
    return {
      ...state,
      dismissedNotificationIds: state.dismissedNotificationIds.concat(payload),
    };
  })
  .case(hideNotification, (state, payload) => {
    return {
      ...state,
      hiddenNotificationIds: state.hiddenNotificationIds.concat(payload),
    };
  });
