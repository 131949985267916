import { Observable } from 'rxjs';
import { AjaxRequest } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { RaidReportResponse } from '../../types/raid-report';
import { createRequest } from '../util';
import { API_PATH, USER_URL } from './constants';

export const createUserPostRequest = <T>(
  path: Array<string | number>,
  body: any
): Observable<T> => {
  path.unshift(API_PATH);
  path.unshift(USER_URL);
  const ajaxSettings: AjaxRequest = {
    method: 'POST',
    body,
    headers: { 'Content-Type': 'application/json' },
  };
  return createRequest<RaidReportResponse<T>>(
    path,
    undefined,
    ajaxSettings
  ).pipe(map((res) => res.response));
};
